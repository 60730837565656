/* eslint-disable max-len */
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { KEY_MESSAGES_STATUS } from 'basics/constants/common.constant';
import { BiogenFunction, Channel, KeyMessageType } from 'basics/enums/maya.enums';
import useGetKeyMessagesLazyQuery from 'basics/graphql/queries/getKeyMessagesLazy';
import useKeyMessagesOptions from 'basics/options/keyMessages.options';
import { KeyMessagesOptionType } from 'basics/types/keyMessages.types';
import { CepStateType, TacticStateType } from 'basics/types/maya.types';
import { warningToast } from 'basics/utils/toast';
import { CustomerInitiative } from 'generated/maya.types';
import cepState from 'states/cep.states';
import tacticState from 'states/tactic.states';

const useTacticKeyMessages = (executingTeam: string, selectedChannel: Channel | null) => {
  const flags = useFlags();
  const { t } = useTranslation();

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const tacticStateValue = useSnapshot(tacticState) as TacticStateType;

  const customerInitiative = cepStateValue.cep?.customerInitiative as CustomerInitiative;
  const product = cepStateValue.cep?.product;
  const [keyMessagesOptions, setKeyMessagesOptions] = useState<KeyMessagesOptionType[]>([]);
  const [isKeyMessagesLoaded, setIsKeyMessagesLoaded] = useState<boolean>(false);

  const keyMessagesFilters = {
    Country: customerInitiative?.country,
    Product: product,
    ActiveStatusFlag: KEY_MESSAGES_STATUS,
  };

  const isKeyMessageMandatory = (currentChannel: Channel) => {
    return ([
      Channel.f2fMeeting,
      Channel.oneToOne,
      Channel.telephone,
      Channel.virtualMeeting,
    ].includes(currentChannel));
  };

  const skipValue = (!keyMessagesFilters.Country || !keyMessagesFilters.Product);

  const {
    getKeyMessagesLazy: fetchKeyMessages,
    data: keyMessages,
    loading: keyMessagesLoading,
  } = useGetKeyMessagesLazyQuery();
  const {
    getKeyMessagesLazy: fetchMedicalStrategies,
    data: medicalStrategies,
    loading: medicalStrategiesLoading,
  } = useGetKeyMessagesLazyQuery();
  const {
    getKeyMessagesLazy: fetchInternationalMedicalStrategies,
    data: internationalMedicalStrategies,
    loading: internationalMedicalStrategiesLoading,
  } = useGetKeyMessagesLazyQuery();
  const {
    getKeyMessagesLazy: fetchDiscussionTopics,
    data: discussionTopics,
    loading: discussionTopicsLoading,
  } = useGetKeyMessagesLazyQuery();

  const queryConfigs = [
    {
      fetch: fetchKeyMessages,
      filters: { ...keyMessagesFilters, Type: KeyMessageType.keyMessage },
      skip: skipValue,
    },
    {
      fetch: fetchMedicalStrategies,
      filters: { ...keyMessagesFilters, Type: KeyMessageType.medicalStrategy },
      skip: skipValue || !flags.mayaBiogenlinc4369EnableFetchAllKeyMessagesTypes,
    },
    {
      fetch: fetchDiscussionTopics,
      filters: { ...keyMessagesFilters, Type: KeyMessageType.discussionTopic },
      skip: skipValue || !flags.mayaBiogenlinc4369EnableFetchAllKeyMessagesTypes,
    },
    {
      fetch: fetchInternationalMedicalStrategies,
      filters: { ...keyMessagesFilters, Country: 'INT', Type: KeyMessageType.medicalStrategy },
      skip: skipValue || !flags.mayaBiogenlinc4369EnableFetchAllKeyMessagesTypes || !flags.mayaAkma299EnableIntFilterForKeyMessages,
    },
  ];

  const delayExecution = (ms: number) => {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  useEffect(() => {
    const fetchSequentially = async () => {
      await queryConfigs.reduce(async (prevPromise, { fetch, filters, skip }, index) => {
        await prevPromise;
        if (skip) return Promise.resolve();
        await fetch({ variables: { filters } }, skip);
        if (index < queryConfigs.length - 1) {
          await delayExecution(200);
        }
        return Promise.resolve();
      }, Promise.resolve());
      setIsKeyMessagesLoaded(true);
    };

    fetchSequentially();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allKeyMessages = [
    ...(keyMessages || []),
    ...(medicalStrategies || []),
    ...(internationalMedicalStrategies || []),
    ...(discussionTopics || []),
  ];

  const currentKeyMessageOption = tacticStateValue.tactic?.keyMessage
    ? [{ ID: tacticStateValue.tactic?.keyMessage?.id, KeyName: tacticStateValue.tactic?.keyMessage?.name }]
    : [];

  const allKeyMessagesOptions = useKeyMessagesOptions(
    !allKeyMessages.length
      ? currentKeyMessageOption
      : allKeyMessages,
  );

  const getBiogenFunction = (executionTeam: string, keyMessageElement: KeyMessagesOptionType) => {
    switch (executionTeam) {
      case BiogenFunction.Commercial:
        return keyMessageElement.commercial;
      case BiogenFunction.Medical:
        return keyMessageElement.medical;
      case BiogenFunction.MarketAccess:
        return keyMessageElement.marketAccess;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (executingTeam && isKeyMessagesLoaded) {
      const filteredKeyMessagesOptions: KeyMessagesOptionType[] = allKeyMessagesOptions.filter((element) => {
        if (!flags.mayaBiogenlinc5479KeyMessagePerExecutingTeam) {
          return true;
        }

        return getBiogenFunction(executingTeam, element);
      });

      const isDefaultExecutionTeam = tacticStateValue.tactic?.executingTeam === executingTeam;

      if (isDefaultExecutionTeam && !flags.mayaAkma299EnableIntFilterForKeyMessages) {
        const isCurrentKMInKMList = filteredKeyMessagesOptions.find((keyMessage) => keyMessage.id === tacticStateValue.tactic?.keyMessage?.id);

        if (!isCurrentKMInKMList && tacticStateValue?.tactic?.keyMessage?.id) {
          filteredKeyMessagesOptions.push({
            id: String(tacticStateValue.tactic.keyMessage.id),
            label: String(tacticStateValue.tactic.keyMessage.name),
            medical: executingTeam === BiogenFunction.Medical,
            commercial: executingTeam === BiogenFunction.Commercial,
            marketAccess: executingTeam === BiogenFunction.MarketAccess,
          });
        }
      }

      if (isKeyMessageMandatory(selectedChannel as Channel) && !filteredKeyMessagesOptions.length && flags.mayaBdcfcsd1196ImplementAlertSystem) {
        warningToast(t('maya_no_key_messages_warning'));
      }

      setKeyMessagesOptions(filteredKeyMessagesOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executingTeam, isKeyMessagesLoaded]);

  return {
    keyMessagesOptions,
    keyMessagesLoading: keyMessagesLoading || medicalStrategiesLoading || internationalMedicalStrategiesLoading || discussionTopicsLoading,
  };
};

export default useTacticKeyMessages;
