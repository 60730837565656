import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  loginRoute,
  mayaCepCreateRoute,
  mayaCepListRoute,
  mayaCustomerInitiativeCreateRoute,
  mayaCustomerInitiativeEditRoute,
  mayaCustomerInitiativeDuplicateRoute,
  mayaCustomerInitiativeListRoute,
  mayaRoute,
  mayaStrategicImperativeCreateRoute,
  mayaStrategicImperativeListRoute,
  mayaImportExportRoute,
  mayaCiTemplateCreateRoute,
  mayaCiTemplateListRoute,
  mayaCiTemplateEditRoute,
  mayaCepEditRoute,
  mayaCepDuplicateRoute,
  mayaCepTemplateListRoute,
  mayaCepTemplateCreateRoute,
  mayaCepTemplateEditRoute,
  mayaCepTrashedListRoute,
  mayaCepViewRoute,
  mayaCiViewRoute,
  mayaStrategicImperativeEditRoute,
  mayaStrategicImperativeDuplicateRoute,
  mayaTacticPrintRoute,
  mayaSiViewRoute,
  mayaCepTemplateSelectorRoute,
  mayaAdminDashboardRoute,
} from 'basics/constants/routes.constants';
import IdleTimerDialog from 'components/IdleTimerDialog';
import CepCreate from 'routes/AuthenticatedApp/CepCreate/CepCreate';
import CepDuplicate from 'routes/AuthenticatedApp/CepDuplicate/CepDuplicate';
import CepEdit from 'routes/AuthenticatedApp/CepEdit/CepEdit';
import CepList from 'routes/AuthenticatedApp/CepList/CepList';
import CepTemplateCreate from 'routes/AuthenticatedApp/CepTemplateCreate/CepTemplateCreate';
import CepTemplateEdit from 'routes/AuthenticatedApp/CepTemplateEdit/CepTemplateEdit';
import CepTemplateList from 'routes/AuthenticatedApp/CepTemplateList/CepTemplateList';
import CepTemplateSelector from 'routes/AuthenticatedApp/CepTemplateSelector/CepTemplateSelector';
import CepTrashedList from 'routes/AuthenticatedApp/CepTrashedList/CepTrashedList';
import CepView from 'routes/AuthenticatedApp/CepView/CepView';
import CiTemplateCreate from 'routes/AuthenticatedApp/CiTemplateCreate/CiTemplateCreate';
import CiTemplateEdit from 'routes/AuthenticatedApp/CiTemplateEdit/CiTemplateEdit';
import CiTemplateList from 'routes/AuthenticatedApp/CiTemplatesList/CiTemplateList';
import CustomerInitiativeCreate from 'routes/AuthenticatedApp/CustomerInitiativeCreate/CustomerInitiativeCreate';
import CustomerInitiativeDuplicate from 'routes/AuthenticatedApp/CustomerInitiativeDuplicate/CustomerInitiativeDuplicate';
import CustomerInitiativeEdit from 'routes/AuthenticatedApp/CustomerInitiativeEdit/CustomerInitiativeEdit';
import CustomerInitiativeList from 'routes/AuthenticatedApp/CustomerInitiativeList/CustomerInitiativeList';
import CustomerInitiativeView from 'routes/AuthenticatedApp/CustomerInitiativeView/CustomerInitiativeView';
import Dashboard from 'routes/AuthenticatedApp/Dashboard/Dashboard';
import AopImportExport from 'routes/AuthenticatedApp/ImportExport/AopImportExport';
import Maya from 'routes/AuthenticatedApp/Maya/Maya';
import NotFound from 'routes/AuthenticatedApp/NotFound';
import StrategicImperativeCreate from 'routes/AuthenticatedApp/StrategicImperativeCreate/StrategicImperativeCreate';
import StrategicImperativeDuplicate from 'routes/AuthenticatedApp/StrategicImperativeDuplicate/StrategicImperativeDuplicate';
import StrategicImperativeEdit from 'routes/AuthenticatedApp/StrategicImperativeEdit/StrategicImperativeEdit';
import StrategicImperativeList from 'routes/AuthenticatedApp/StrategicImperativeList/StrategicImperativeList';
import StrategicImperativeView from 'routes/AuthenticatedApp/StrategicImperativeView/StrategicImperativeView';
import TacticPrintView from 'routes/AuthenticatedApp/TacticPrintView/TacticPrintView';

const AuthenticatedApp: FC = () => {
  const flags = useFlags();

  return (
    <>
      <IdleTimerDialog />
      <Routes>
        <Route path="*" element={ <NotFound /> } />
        <Route path={ loginRoute } element={ <Navigate to="/" replace /> } />
        <Route path={ mayaRoute } element={ <Maya /> } />
        <Route path={ mayaStrategicImperativeListRoute } element={ <StrategicImperativeList /> } />
        <Route path={ mayaStrategicImperativeCreateRoute } element={ <StrategicImperativeCreate /> } />
        <Route path={ mayaStrategicImperativeEditRoute } element={ <StrategicImperativeEdit/> } />
        <Route path={ mayaStrategicImperativeDuplicateRoute } element={ <StrategicImperativeDuplicate /> } />
        <Route path={ mayaSiViewRoute } element={ <StrategicImperativeView /> } />
        <Route path={ mayaCustomerInitiativeListRoute } element={ <CustomerInitiativeList /> } />
        <Route path={ mayaCustomerInitiativeCreateRoute } element={ <CustomerInitiativeCreate /> } />
        <Route path={ mayaCepTemplateSelectorRoute } element={ <CepTemplateSelector /> } />
        <Route path={ mayaCiTemplateCreateRoute } element={ <CiTemplateCreate /> } />
        <Route path={ mayaCiTemplateEditRoute } element={ <CiTemplateEdit /> } />
        <Route path={ mayaCustomerInitiativeDuplicateRoute } element={ <CustomerInitiativeDuplicate /> } />
        <Route path={ mayaCustomerInitiativeEditRoute } element={ <CustomerInitiativeEdit /> } />
        <Route path={ mayaCiTemplateListRoute } element={ <CiTemplateList /> } />
        <Route path={ mayaCiViewRoute } element={ <CustomerInitiativeView /> } />
        <Route path={ mayaCepListRoute } element={ <CepList /> } />
        <Route path={ mayaCepTemplateListRoute } element={ <CepTemplateList /> } />
        <Route path={ mayaCepCreateRoute } element={ <CepCreate /> } />
        <Route path={ mayaCepTemplateCreateRoute } element={ <CepTemplateCreate /> } />
        <Route path={ mayaCepTemplateEditRoute } element={ <CepTemplateEdit /> } />
        <Route path={ mayaCepEditRoute } element={ <CepEdit /> } />
        <Route path={ mayaCepDuplicateRoute } element={ <CepDuplicate /> } />
        <Route path={ mayaCepTrashedListRoute } element={ <CepTrashedList /> } />
        <Route path={ mayaCepViewRoute } element={ <CepView /> } />
        <Route path={ mayaTacticPrintRoute } element={ <TacticPrintView /> } />
        {
          flags.mayaEnableAopImportBiogenlinc32 && (
            <Route path={ mayaImportExportRoute } element={ <AopImportExport /> } />
          )
        }
        {
          flags.mayaBdcfcsd2528EnableDashboardPage && (
            <Route path={ mayaAdminDashboardRoute } element={ <Dashboard /> } />
          )
        }
      </Routes>
    </>
  );
};

export default AuthenticatedApp;
