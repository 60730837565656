import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaFontSize, YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

export const tacticGroupBoxStyle: SxProps<Theme> = {
  position: 'relative',
};

export const actionButtonStyle: SxProps<Theme> = {
  fontSize: YodaFontSize.small,
};

export const tacticGroupCircularProgressSize = 32;

export const tacticGroupCircularProgressStyle: SxProps<Theme> = {
  position: 'absolute',
  top: `calc(50% - ${tacticGroupCircularProgressSize}px)`,
  left: `calc(50% - ${tacticGroupCircularProgressSize}px)`,
};

export const tacticGroupButtonBoxStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: YodaJustifyContent.spaceBetween,
};

export const titleGroupStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: YodaSpacing.large,
};

export const titleBoxStyle: SxProps<Theme> = {
  width: '78%',
};

export const suffixBoxStyle: SxProps<Theme> = {
  width: '20%',
  '& label': {
    lineHeight: YodaFontSize.xLarge,
  },
};
