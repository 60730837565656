import { useEffect } from 'react';
import useCountTargetsQuery from 'basics/graphql/queries/countTargets';
import { DashboardFilter } from 'generated/maya.types';

const useCountTargetsWidget = (filters?: DashboardFilter, useAdminFilters = false) => {
  const { countTargetsQuery, targetsData, loading, error } = useCountTargetsQuery();

  useEffect(() => {
    if (filters) {
      countTargetsQuery({ variables: { filters, useAdminFilters } });
    }
  }, [filters, countTargetsQuery, useAdminFilters]);

  return {
    value: targetsData,
    loading,
    error,
  };
};

export default useCountTargetsWidget;
