export const loginRoute = '/login';

export const mayaRoute = '/';

export const mayaStrategicImperativeListRoute = '/strategicImperatives';
export const mayaCustomerInitiativeListRoute = '/customerInitiatives';
export const mayaCiTemplateListRoute = '/ciTemplate';
export const mayaCepListRoute = '/customerEngagementPlannings';
export const mayaCepTemplateListRoute = '/cepTemplate';

export const mayaStrategicImperativeCreateRoute = '/strategicImperative/create';
export const mayaCustomerInitiativeCreateRoute = '/customerInitiative/create';
export const mayaCiTemplateCreateRoute = '/ciTemplate/create';
export const mayaCepCreateRoute = '/cepCreate/create';
export const mayaCepTemplateCreateRoute = '/cepTemplate/create';
export const mayaCepTemplateSelectorRoute = '/cepTemplate/select';

export const mayaStrategicImperativeEditRoute = '/strategicImperative/edit';
export const mayaCustomerInitiativeEditRoute = '/customerInitiative/edit';
export const mayaCiTemplateEditRoute = '/ciTemplate/edit';
export const mayaCepEditRoute = '/customerEngagementPlanning/edit';
export const mayaCepTemplateEditRoute = '/cepTemplate/edit';

export const mayaStrategicImperativeDuplicateRoute = '/strategicImperative/duplicate';
export const mayaCustomerInitiativeDuplicateRoute = '/customerInitiative/duplicate';
export const mayaCepDuplicateRoute = '/customerEngagementPlanning/duplicate';

export const mayaSiViewRoute = '/strategicImperative/view';
export const mayaCiViewRoute = '/customerInitiative/view';
export const mayaCepViewRoute = '/customerEngagementPlanning/view';

export const mayaImportExportRoute = '/importAndExport';
export const mayaCepTrashedListRoute = '/customerEngagementPlanning/trashed';
export const mayaTacticPrintRoute = '/customerEngagementPlanning/tacticPrint';

export const mayaAdminDashboardRoute = '/dashboard';
