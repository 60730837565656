import { useEffect } from 'react';
import useGetTargetsEngagementQuery from 'basics/graphql/queries/getTargetsEngagement';
import { DashboardFilter } from 'generated/maya.types';

const useTargetsEngagementWidget = (filters?: DashboardFilter, useAdminFilters = false) => {
  const { getTargetsEngagementQuery, targetsEngagement, loading, error } = useGetTargetsEngagementQuery();

  useEffect(() => {
    if (filters) {
      getTargetsEngagementQuery({ variables: { filters, useAdminFilters } });
    }
  }, [filters, getTargetsEngagementQuery, useAdminFilters]);

  return {
    value: targetsEngagement,
    loading,
    error,
  };
};

export default useTargetsEngagementWidget;
