import { ProductType } from '../types/products.types';

export const getTherapeuticAreaByProduct = (productExternalID?: string, allProducts?: ProductType[]): string | undefined => {
  if (!productExternalID) {
    return '';
  }

  const foundProduct = allProducts?.find(
    (product: ProductType) => product.productExternalID.toLowerCase() === productExternalID.toLowerCase(),
  );

  return foundProduct?.therapeuticArea;
};
