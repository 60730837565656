import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { ProductType } from '../../types/products.types';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_ALL_PRODUCT_LAZY = gql`
  query getAllProducts {
    getAllProducts {
      productExternalID
      productID
      productName
      therapeuticArea
    }
  }
`;

type UseGetAllProductsLazy = () => {
  getAllProductsLazy: Function;
  loading: boolean;
  error?: ApolloError;
  data: ProductType[];
};

type ResultType = {
  getAllProducts: ProductType[];
};

const useGetAllProductsLazy: UseGetAllProductsLazy = () => {
  const [getAllProductsLazy, { data, loading, error }] = useLazyQuery<ResultType>(GET_ALL_PRODUCT_LAZY, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getAllProductsLazy.ts - useGetAllProductsLazy',
    });
  }

  return {
    getAllProductsLazy,
    data: data?.getAllProducts || [],
    loading,
    error,
  };
};
export default useGetAllProductsLazy;
