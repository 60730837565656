import { GridCellParams } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGINATION_PAGE_SIZE } from 'basics/constants/common.constant';
import { mayaCustomerInitiativeEditRoute } from 'basics/constants/routes.constants';
import useGetDashboardCIQuery from 'basics/graphql/queries/getDashboardCI';
import useCiAdminDashboardListConfig from 'components/AdminDashboard/CiAdminDashboardGridlist/CiAdminDashboardList.config';
import { CustomerInitiative, DashboardFilter } from 'generated/maya.types';

const useCiAdminDashboardList = (filters?: DashboardFilter, useAdminFilters = false) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(0);
  const [cis, setCis] = useState<CustomerInitiative[]>([]);
  const navigate = useNavigate();

  const { getDashboardCIQuery, ciCount, ciData, loading } = useGetDashboardCIQuery();

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  useMemo(() => {
    if (filters) {
      getDashboardCIQuery({ variables: { filters, queryOptions, useAdminFilters } });
    }
  }, [filters, getDashboardCIQuery, queryOptions, useAdminFilters]);

  useEffect(() => {
    if (!loading && filters) {
      setCis(ciData);
    }
  }, [ciData, filters, loading]);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (ciCount !== undefined ? ciCount as number : prevRowCountState));
  }, [ciCount, setRowCountState]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const { customerInitiativeListColumnConfig } = useCiAdminDashboardListConfig();

  const onCellClick = (params: GridCellParams) => {
    navigate(`${mayaCustomerInitiativeEditRoute}?id=${params.row._id}`);
  };

  return {
    customerInitiativeListColumnConfig,
    cis,
    loading,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    onCellClick,
  };
};

export default useCiAdminDashboardList;
