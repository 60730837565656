import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProductsOptionType, ProductType } from '../types/products.types';
import { therapeuticAreaRelativesProducts } from 'basics/constants/common.constants';
import { AllValidTherapeuticArea, BiogenProduct } from 'basics/enums/biogen.enums';

const getStaticProductOptions = (selectedTA: string | string[]): ProductsOptionType[] => {
  const allProducts = Object.entries(BiogenProduct).map(([key, value]) => ({
    id: value,
    label: key,
    therapeuticArea: Object.keys(therapeuticAreaRelativesProducts)
      .find((ta: string) => therapeuticAreaRelativesProducts[ta as keyof typeof therapeuticAreaRelativesProducts]?.includes(value)) || '',
  }));

  if (!selectedTA || (Array.isArray(selectedTA) && selectedTA.length === 0)) {
    return allProducts;
  }

  if (typeof (selectedTA) === 'string') {
    return allProducts.filter((product) => product.therapeuticArea === selectedTA);
  }

  const filteredProducts = allProducts.filter(({ id }) => {
    return selectedTA.some((ta) => therapeuticAreaRelativesProducts[ta as keyof typeof therapeuticAreaRelativesProducts]?.includes(id));
  });

  return filteredProducts;
};

const getDynamicProductOptions = (products: ProductType[], selectedTA: string | string[]): ProductsOptionType[] => {
  if (!products.length) return [];

  const createOption = (product: ProductType): ProductsOptionType => ({
    id: product.productExternalID as string,
    label: product.productName as string,
    therapeuticArea: product.therapeuticArea as string,
  });

  if (!selectedTA || (Array.isArray(selectedTA) && selectedTA.length === 0)) {
    return products.map(createOption);
  }

  if (typeof (selectedTA) === 'string') {
    return products.filter((product) => product.therapeuticArea === selectedTA).map(createOption);
  }

  const filteredProducts = products.filter((product) => {
    return selectedTA.includes(product.therapeuticArea as string);
  });

  return filteredProducts.map(createOption);
};

const normalizeTAOptions = (TAOption: string | string[]) => {
  if (typeof TAOption === 'string') {
    return TAOption === AllValidTherapeuticArea.BIOSIMS ? AllValidTherapeuticArea.BBU : TAOption;
  }

  return TAOption.map((ta) => (ta === AllValidTherapeuticArea.BIOSIMS ? AllValidTherapeuticArea.BBU : ta));
};

const useProductOptions = (TAOption: string | string[], products: ProductType[]): ProductsOptionType[] => {
  const flags = useFlags();

  return flags.mayaBdcfcsd2599EnableDynamicProductDisplay
    ? getDynamicProductOptions(products, normalizeTAOptions(TAOption))
    : getStaticProductOptions(TAOption);
};

export default useProductOptions;
