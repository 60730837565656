import { FC } from 'react';
import { AlertMessageType } from 'basics/enums/maya.enums';
import AdminDashboard from 'components/AdminDashboard/AdminDashboard';
import AlertBanner from 'components/AlertBanner/AlertBanner';
import MayaLayout from 'components/MayaLayout';

const Dashboard: FC = () => {
  return (
    <MayaLayout>
      <AlertBanner type={ AlertMessageType.VeevaFatalErrorAlert } />
      <AdminDashboard />
    </MayaLayout>
  );
};

export default Dashboard;
