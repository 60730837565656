export const removeValueFromString = <T extends Record<string, string>>(
  str: string,
  listOfValues: T,
): string => {
  if (!str || !listOfValues || Object.values(listOfValues).length === 0) {
    return str;
  }

  const escapedValues = Object.values(listOfValues).map((suffix) => suffix.replace(/[-\s]/g, '\\$&')).join('|');
  const regex = new RegExp(`(${escapedValues})$`);
  return str.replace(regex, '').trim();
};
