import { Buffer } from 'buffer';
import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { StreamableBuffer } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const EXPORT_ADMIN_DASHBOARD_DATA = gql`
query ExportAdminDashboardData($filters: DashboardFilter!) {
  exportAdminDashboardData(filters: $filters) {
    buffer
  }
}
`;

type ResultType = {
  exportAdminDashboardData: StreamableBuffer;
};

type UseExportAdminDashboardData = () => {
  data: unknown;
  error?: ApolloError;
  exportAdminDashboardData: Function;
  loading: boolean;
  refetch: Function;
};

const useExportAdminDashboardDataQuery: UseExportAdminDashboardData = () => {
  const [exportAdminDashboardData, { data, loading, error, refetch }] = useLazyQuery<ResultType>(EXPORT_ADMIN_DASHBOARD_DATA, {
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      customContext,
      error,
      transactionName: 'exportAdminDashboardData.ts - useExportAdminDashboardDataQuery',
    });
  }

  return {
    data: data?.exportAdminDashboardData?.buffer ? Buffer.from(data.exportAdminDashboardData.buffer, 'base64') : null,
    error,
    exportAdminDashboardData,
    loading,
    refetch,
  };
};

export default useExportAdminDashboardDataQuery;
