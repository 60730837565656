import { useEffect } from 'react';
import useCountCepQuery from 'basics/graphql/queries/countCep';
import { DashboardFilter } from 'generated/maya.types';

const useCountCepWidget = (filters?: DashboardFilter, useAdminFilters = false) => {
  const { countCepQuery, cepData, loading, error } = useCountCepQuery();

  useEffect(() => {
    if (filters) {
      countCepQuery({ variables: { filters, useAdminFilters } });
    }
  }, [filters, countCepQuery, useAdminFilters]);

  return {
    value: cepData,
    loading,
    error,
  };
};

export default useCountCepWidget;
