import { useEffect } from 'react';
import useGetDashboardCIQuery from 'basics/graphql/queries/getDashboardCI';
import { DashboardFilter } from 'generated/maya.types';

const useCountCiWidget = (filters?: DashboardFilter, useAdminFilters = false) => {
  const { getDashboardCIQuery, ciCount, loading, error } = useGetDashboardCIQuery();

  useEffect(() => {
    if (filters) {
      getDashboardCIQuery({ variables: { filters, limit: 5000, offset: 0, useAdminFilters } });
    }
  }, [filters, getDashboardCIQuery, useAdminFilters]);

  return {
    value: ciCount,
    loading,
    error,
  };
};

export default useCountCiWidget;
