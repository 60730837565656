import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

const useCiAdminDashboardListConfig = (
) => {
  const { t } = useTranslation();

  const ciTitleFieldConfig = {
    label: t('maya_ci_form_ci_title_label'),
    name: 'name',
    placeholder: t('maya_ci_form_ci_title_placeholder'),
    defaultValue: '',
    required: false,
  };

  const customerInitiativeListColumnConfig: GridColDef[] = [
    {
      field: 'title',
      headerName: t('maya_ci_dashboard_title'),
      flex: 1,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
  ];

  return {
    customerInitiativeListColumnConfig,
    ciTitleFieldConfig,
  };
};

export default useCiAdminDashboardListConfig;
