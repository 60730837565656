import { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAdminDashboardFiltersConfig from './AdminDashboardFilters.config';
import { AllValidTherapeuticArea } from 'basics/enums/biogen.enums';
import { useCreateOrUpdateMayaUserDataMutation } from 'basics/graphql/mutations/createOrUpdateMayaUserData';
import useExportAdminDashboardDataQuery from 'basics/graphql/queries/exportAdminDashboardData';
import { SelectOption } from 'basics/options/options.types';
import { CurrentUser } from 'basics/types/user.types';
import { errorToast, succesToast } from 'basics/utils/toast';
import { formatAdminDashboardFilter } from 'components/AdminDashboardFilters/AdminDashboardFilters.transformer';
import { ProductsContext } from 'contexts/products';
import { CurrentUserStateContext, useCurrentUserState } from 'contexts/user';
import { DashboardFilter } from 'generated/maya.types';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useAdminDashboardFilters = () => {
  const [selectedTA, setSelectedTA] = useState<string[] | null>(null);
  const [filters, setFilters] = useState<DashboardFilter | undefined>();
  const { getValues, providerFields, setValue, useWatchForm, useWatchField } = useYodaCreateForm();
  const { isValid } = useWatchForm();
  const { t } = useTranslation();
  const { user } = useCurrentUserState();
  const { setCurrentUserData } = useContext(CurrentUserStateContext);
  const { adminDashboardFiltersConfig } = useAdminDashboardFiltersConfig(selectedTA, user);
  const [createOrUpdateMayaUserData] = useCreateOrUpdateMayaUserDataMutation();
  const therapeuticAreaKey = useWatchField(adminDashboardFiltersConfig.therapeuticArea.name)?.value;
  const [triggerDownload, setTriggerDownload] = useState<boolean>(false);
  const { data, error, exportAdminDashboardData, loading: exportLoading } = useExportAdminDashboardDataQuery();
  const allProducts = useContext(ProductsContext);

  useEffect(() => {
    setSelectedTA(therapeuticAreaKey);
  }, [therapeuticAreaKey]);

  useEffect(() => {
    const filtersValues = getValues();
    if (filtersValues.countries.length || filtersValues.therapeuticArea.length) {
      const formattedFilters = formatAdminDashboardFilter(filtersValues);
      setFilters(formattedFilters);

      if (formattedFilters?.therapeuticArea?.includes(AllValidTherapeuticArea.BBU)) {
        setFilters({
          ...formattedFilters,
          therapeuticArea: [...formattedFilters.therapeuticArea, AllValidTherapeuticArea.BIOSIMS],
        });
      }

      createOrUpdateMayaUserData({ adminDashboardFilters: formattedFilters });

      setCurrentUserData((prevState) => {
        return {
          ...prevState,
          currentUser: {
            ...prevState.currentUser,
            adminDashboardFilters: formattedFilters,
          } as CurrentUser,
        };
      });
    }
  }, [createOrUpdateMayaUserData, getValues, setCurrentUserData]);

  useEffect(() => {
    if (error) {
      setTriggerDownload(false);
    }

    if (!exportLoading && data && triggerDownload) {
      const url = URL.createObjectURL(new Blob(
        [data as Buffer],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      ));
      const event = new MouseEvent('click');
      const el = document.createElement('a');
      el.setAttribute('href', url);
      el.setAttribute('download', `export_maya_${new Date().toISOString().split('T')[0]}`);
      el.dispatchEvent(event);
      URL.revokeObjectURL(url);

      setTriggerDownload(false);
      succesToast(t('maya_admin_dashboard_data_exported'));
    } else if (triggerDownload) {
      errorToast(t('maya_admin_dashboard_no_data_to_exported'));
    }
  }, [exportLoading, data, error, triggerDownload, t]);

  const handleChangeTA = useCallback((event: SyntheticEvent<Element, Event>, TAValue: SelectOption[]) => {
    if (TAValue.length === 0) {
      setValue(adminDashboardFiltersConfig.product.name, []);
    }
  }, [setValue, adminDashboardFiltersConfig]);

  const handleSearchValueOfUserFilters = () => {
    const formattedFilters = formatAdminDashboardFilter(getValues());
    setFilters(formattedFilters);

    if (formattedFilters?.therapeuticArea?.includes(AllValidTherapeuticArea.BBU)) {
      setFilters({
        ...formattedFilters,
        therapeuticArea: [...formattedFilters.therapeuticArea, AllValidTherapeuticArea.BIOSIMS],
      });
    }

    createOrUpdateMayaUserData({ adminDashboardFilters: formattedFilters });

    setCurrentUserData((prevState) => {
      return {
        ...prevState,
        currentUser: {
          ...prevState.currentUser,
          adminDashboardFilters: formattedFilters,
        } as CurrentUser,
      };
    });
  };

  const handleExportDataOfUserFilters = async () => {
    const formattedFilters = formatAdminDashboardFilter(getValues());
    if (formattedFilters?.therapeuticArea?.includes(AllValidTherapeuticArea.BBU)) {
      formattedFilters?.therapeuticArea?.push(AllValidTherapeuticArea.BIOSIMS);
    }

    await exportAdminDashboardData(
      {
        variables: {
          filters: formattedFilters,
        },
      },
    );

    setTriggerDownload(true);
  };

  const resetDashboardFilter = () => {
    const dashboardFields = Object.keys(adminDashboardFiltersConfig);

    dashboardFields.forEach((field) => {
      const fieldName = adminDashboardFiltersConfig[field as keyof typeof adminDashboardFiltersConfig].name;

      const isArrayField = [
        adminDashboardFiltersConfig.countries.name,
        adminDashboardFiltersConfig.therapeuticArea.name,
        adminDashboardFiltersConfig.product.name,
        adminDashboardFiltersConfig.cepStatus.name,
        adminDashboardFiltersConfig.cepStatus.name,
      ].includes(field);

      setValue(
        fieldName,
        isArrayField ? [] : '',
      );
    });
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: handleSearchValueOfUserFilters,
      buttonType: ButtonType.primary,
      disabled: !isValid,
    },
    label: t('actions_search_label'),
  };

  const exportDataButtonConfig = {
    buttonProps: {
      onClick: handleExportDataOfUserFilters,
      buttonType: ButtonType.neutral,
      disabled: exportLoading,
    },
    label: t('maya_actions_export_data_label'),
  };

  const resetButtonConfig = {
    buttonProps: {
      onClick: resetDashboardFilter,
      buttonType: ButtonType.secondary,
    },
    label: t('actions_reset_label'),
  };

  return {
    providerFields,
    adminDashboardFiltersConfig,
    handleChangeTA,
    submitButtonConfig,
    resetButtonConfig,
    exportDataButtonConfig,
    filters,
    allProducts,
  };
};

export default useAdminDashboardFilters;
