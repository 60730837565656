import { FC } from 'react';
import useCepAdminDashboardList from './CepAdminDashboardList.hook';
import { CountWidgetPropsType } from 'components/CountWidgets/CountWidget.types';
import GridList from 'components/GridList/GridList';

const CepAdminDashboardList: FC<CountWidgetPropsType> = ({ filters, useAdminFilters }) => {
  const {
    cepListColumnConfig,
    getFilteredRows,
    cepLoading,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    onCellClick,
  } = useCepAdminDashboardList(filters, useAdminFilters);

  return (
    <GridList
      loading={ cepLoading }
      getRowId={ (row) => row._id }
      columnConfig={ cepListColumnConfig }
      rows={ getFilteredRows() }
      onPageChange={ onPageChange }
      onPageSizeChange={ onPageSizeChange }
      rowCountState={ rowCountState }
      disableColumnMenu={ true }
      onCellClick={ onCellClick }
    />
  );
};

export default CepAdminDashboardList;
