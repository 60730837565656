import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  mainContainerStyle,
  titleBoxStyle,
  descriptionBoxStyle,
  imageBoxStyle,
} from './Maintenance.styles';
import MaintenanceSVG from 'basics/images/Maintenance.svg';
import LargeContainer from 'components/Layout/LargeContainer';
import Box from 'yoda-ui/components/Box';

const Maintenance: FC = () => {
  const { t } = useTranslation();
  const flags = useFlags();
  const maintenancePage = flags.mayaBdcfcsd1532EnableMaintenancePage;

  return (
    <LargeContainer sx={ mainContainerStyle } display='block'>
      <Box sx={ titleBoxStyle }>
        { t('maya_maintenance_page_title') }
      </Box>
      <Box sx={ descriptionBoxStyle }>
        { maintenancePage.description }
      </Box>
      <Box sx={ imageBoxStyle }>
        <img alt="" src={ MaintenanceSVG } width="100%"/>
      </Box>
    </LargeContainer>
  );
};
export default Maintenance;
