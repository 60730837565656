import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useTacticFormSchema from './TacticForm.schema';
import { TacticFormConfigType } from './TacticForm.types';
import { mayaCepTemplateCreateRoute, mayaCepTemplateEditRoute } from 'basics/constants/routes.constants';
import { CepStatus, Channel } from 'basics/enums/maya.enums';
import biogenFunctionOptions from 'basics/options/biogenFunction.options';
import channelsOptions from 'basics/options/channel.options';
import ctaTypeOptions from 'basics/options/ctaType.options';
import useEngagementMetricOptions from 'basics/options/engagementMetric.options';
import { SelectOption } from 'basics/options/options.types';
import useReachMetricOptions from 'basics/options/reachMetric.options';
import useTacticOptions from 'basics/options/tactic.options';
import { tacticToForm } from 'basics/transformers/Tactic.transformer';
import { CepStateType, TacticStateType } from 'basics/types/maya.types';
import { transformInputDateUTC } from 'basics/utils/dates/dates.utils';
import { Channel as ChannelType, Tactic } from 'generated/maya.types';
import { ButtonVariant } from 'yoda-ui/components/BiogenLincDS/Buttons';
import { SortType } from 'yoda-ui/components/Form/SelectYoda/SelectYoda.types';
import { Icons } from 'yoda-ui/components/Icons/Icon';

const useTacticFormConfig = (
  tacticStateValue: TacticStateType,
  cepStateValue: CepStateType,
  selectedChannel: Channel | null,
  keyMessagesOptions: SelectOption[],
  dropMaterialsOptions: SelectOption[],
  approvedDocumentIdOptions: SelectOption[],
  assetIdOptions: SelectOption[],
  debouncedOnVeevaVaultFieldChange: Function,
  allVeevaVaultDocumentsLoading: boolean,
  tacticGroupsOptions: SelectOption[],
  selectedTacticGroup: Tactic | null,
  handleTacticGroupCreation: React.MouseEventHandler<HTMLButtonElement>,
  tacticGroupLoading: boolean,
  tacticTitlesInSelectedGroup: string[],
  handleTacticGroupChanging: Function,
  handleCallToActionChanging: Function,
) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const TacticFormSchema = useTacticFormSchema(cepStateValue.cep, tacticStateValue.tactic);
  const defaultFormValue = tacticToForm(tacticStateValue.tactic, cepStateValue.cep, flags);
  const dateRange = transformInputDateUTC(defaultFormValue.date || []);
  const location = useLocation();
  const currentURL = location.pathname;
  const templateRoute = [mayaCepTemplateCreateRoute, mayaCepTemplateEditRoute];
  const isKeyMessageMandatory = (currentChannel: Channel) => {
    return ([
      Channel.f2fMeeting,
      Channel.oneToOne,
      Channel.telephone,
      Channel.virtualMeeting,
    ].includes(currentChannel))
  && (!templateRoute.includes(currentURL));
  };

  const childChannelTypes = Object.values(ChannelType).filter((channel) => channel !== ChannelType.group);

  const isCepApproved = cepStateValue.cep?.status === CepStatus.APPROVED && Boolean(tacticStateValue.tactic);
  const cepId = cepStateValue.cep?._id || null;

  const CEPApprovedAndAdvancedEditingDisabled = isCepApproved
  && (!flags.mayaBdcfcsd653ExtendEditingOfApprovedCep || !flags.mayaBdcfcsd1251ExtendTacticEditingOfApprovedCep);

  const tacticGroupElementDisabled = CEPApprovedAndAdvancedEditingDisabled || tacticGroupLoading;

  const tacticFormConfig: TacticFormConfigType = {
    channel: {
      label: t('maya_tactic_form_channel_label'),
      name: 'channel',
      placeholder: t('maya_tactic_form_channel_placeholder'),
      options: channelsOptions,
      required: true,
      defaultValue: defaultFormValue.channel || undefined,
      validation: TacticFormSchema.channel,
      disabled: isCepApproved,
    },
    title: {
      label: t('maya_tactic_form_title_label'),
      name: 'title',
      placeholder: t('maya_tactic_form_title_placeholder'),
      required: true,
      defaultValue: defaultFormValue.title,
      validation: TacticFormSchema.title.trim(),
      disabled: CEPApprovedAndAdvancedEditingDisabled,
    },
    titleSuffix: {
      label: t('maya_tactic_form_title_suffix_label'),
      name: 'titleSuffix',
      placeholder: '',
      required: true,
      defaultValue: defaultFormValue.titleSuffix,
      disabled: true,
    },
    date: {
      startText: t('maya_tactic_date_start_label'),
      endText: t('maya_tactic_date_end_label'),
      name: 'date',
      disablePast: false,
      startRequired: true,
      endRequired: true,
      defaultValue: defaultFormValue.date,
      minDate: isCepApproved ? dateRange.dateStart : null,
      disabledStartRange: CEPApprovedAndAdvancedEditingDisabled,
      validation: TacticFormSchema.date,
      disabled: CEPApprovedAndAdvancedEditingDisabled,
    },
    description: {
      label: t('maya_tactic_form_description_label'),
      name: 'description',
      placeholder: t('maya_tactic_form_description_placeholder'),
      rows: 4,
      multiline: true,
      defaultValue: defaultFormValue.description || undefined,
      disabled: CEPApprovedAndAdvancedEditingDisabled,
    },
    mandatory: {
      label: t('maya_tactic_form_mandatory_label'),
      name: 'mandatory',
      checked: defaultFormValue.mandatory,
      validation: TacticFormSchema.mandatory,
      disabled: CEPApprovedAndAdvancedEditingDisabled,
    },
    includeConsent: {
      label: t('maya_tactic_form_include_consent_label'),
      name: 'includeConsent',
      checked: defaultFormValue.includeConsent || undefined,
      validation: TacticFormSchema.includeConsent,
      disabled: (selectedChannel === Channel.broadcastEmail) || isCepApproved,
    },
    productionOwner: {
      label: t('maya_tactic_form_production_owner_label'),
      name: 'productionOwner',
      placeholder: t('maya_tactic_form_production_owner_placeholder'),
      options: biogenFunctionOptions,
      required: false,
      defaultValue: defaultFormValue.productionOwner,
      validation: TacticFormSchema.productionOwner,
      disabled: isCepApproved,
    },
    executingTeam: {
      label: t('maya_tactic_form_executing_team_label'),
      name: 'executingTeam',
      placeholder: t('maya_tactic_form_executing_team_placeholder'),
      options: biogenFunctionOptions,
      required: true,
      defaultValue: defaultFormValue.executingTeam,
      validation: TacticFormSchema.executingTeam,
      disabled: CEPApprovedAndAdvancedEditingDisabled,
    },
    previousTactic: {
      label: t('maya_tactic_form_previous_tactic_label'),
      name: 'previousTactic',
      placeholder: t('maya_tactic_form_previous_tactic_placeholder'),
      defaultValue: defaultFormValue.previousTactic,
      options: useTacticOptions(cepId, tacticStateValue.tactic?._id ? [tacticStateValue.tactic?._id] : [], childChannelTypes),
      disabled: CEPApprovedAndAdvancedEditingDisabled,
    },
    tacticGroup: {
      label: t('maya_tactic_form_tactic_group_label'),
      name: 'tacticGroup',
      placeholder: t('maya_tactic_form_tactic_group_placeholder'),
      defaultValue: selectedTacticGroup?._id || defaultFormValue.previousTactic,
      options: tacticGroupsOptions,
      disabled: tacticGroupElementDisabled,
      onChange: handleTacticGroupChanging,
      sortType: SortType.Length,
    },
    createTacticGroup: {
      label: t('maya_tactic_form_create_tactic_group_label'),
      icon: Icons.addCircle,
      variant: ButtonVariant.primary,
      disabled: tacticGroupElementDisabled,
      onClick: handleTacticGroupCreation,
    },
    tacticGroupTooltip: {
      listItems: tacticTitlesInSelectedGroup,
    },
    tacticGroupTooltipButton: {
      label: t('maya_tactic_form_show_related_tactics'),
      icon: Icons.lan,
      variant: ButtonVariant.primary,
      disabled: tacticGroupElementDisabled,
    },
    parentTactic: {
      label: t('maya_tactic_form_parent_tactic_label'),
      name: 'parentTactic',
      placeholder: selectedChannel === Channel.approvedEmail
        ? t('maya_tactic_form_parent_tactic_approvedEmail_placeholder')
        : t('maya_tactic_form_parent_tactic_placeholder'),
      defaultValue: defaultFormValue.parentTactic,
      options: useTacticOptions(cepId, tacticStateValue.tactic?._id ? [tacticStateValue.tactic?._id] : [], childChannelTypes),
      disabled: isCepApproved,
    },
    assetId: {
      label: t('maya_tactic_form_asset_id_label'),
      name: 'assetId',
      placeholder: t('maya_tactic_form_asset_id_placeholder'),
      defaultValue: defaultFormValue.assetId || undefined,
      options: assetIdOptions || [],
      freeOption: true,
      onChange: debouncedOnVeevaVaultFieldChange,
      loading: allVeevaVaultDocumentsLoading,
      loadingText: t('maya_tactic_form_veeva_vault_query_loading_text'),
    },
    assetName: {
      label: t('maya_tactic_form_asset_name_label'),
      name: 'assetName',
      placeholder: t('maya_tactic_form_asset_name_placeholder'),
      defaultValue: defaultFormValue.assetName || undefined,
      disabled: (!flags.mayaBdcfcsd653ExtendEditingOfApprovedCep || !flags.mayaBdcfcsd1251ExtendTacticEditingOfApprovedCep)
        ? (isCepApproved && !['f2fEvent', 'virtualEvent', 'hybridEvent'].includes(tacticStateValue.tactic?.channel || '')) : false,
    },
    callToAction: {
      label: t('maya_tactic_form_call_to_action_label'),
      name: 'callToAction',
      placeholder: t('maya_tactic_form_call_to_action_placeholder'),
      options: ctaTypeOptions,
      required: [Channel.approvedEmail, Channel.broadcastEmail, Channel.oneToOne]
        .includes(selectedChannel as Channel),
      defaultValue: defaultFormValue.callToAction,
      validation: TacticFormSchema.callToAction,
      disabled: CEPApprovedAndAdvancedEditingDisabled,
      onChange: handleCallToActionChanging,
    },
    clickUrl: {
      label: t('maya_tactic_form_click_url_label'),
      name: 'clickUrl',
      placeholder: t('maya_tactic_form_click_url_placeholder'),
      defaultValue: defaultFormValue.clickUrl || undefined,
    },
    reachMetric: {
      label: t('maya_tactic_form_reach_metric_label'),
      name: 'reachMetric',
      placeholder: t('maya_tactic_form_reach_metric_placeholder'),
      options: useReachMetricOptions(selectedChannel),
      defaultValue: defaultFormValue.reachMetric,
      validation: TacticFormSchema.reachMetric,
      disabled: isCepApproved,
    },
    reachValue: {
      label: t('maya_tactic_form_reach_value_label'),
      name: 'reachValue',
      placeholder: t('maya_tactic_form_reach_value_placeholder'),
      defaultValue: defaultFormValue.reachValue,
      type: 'number',
      disabled: isCepApproved,
    },
    engagementMetric: {
      label: t('maya_tactic_form_engagement_metric_label'),
      name: 'engagementMetric',
      placeholder: t('maya_tactic_form_engagement_metric_placeholder'),
      options: useEngagementMetricOptions(selectedChannel),
      defaultValue: defaultFormValue.engagementMetric,
      validation: TacticFormSchema.engagementMetric,
      disabled: isCepApproved,
    },
    engagementValue: {
      label: t('maya_tactic_form_engagement_value_label'),
      name: 'engagementValue',
      placeholder: t('maya_tactic_form_engagement_value_placeholder'),
      defaultValue: defaultFormValue.engagementValue,
      type: 'number',
      disabled: isCepApproved,
    },
    keyMessage: {
      label: t('maya_tactic_form_key_message_label'),
      name: 'keyMessage',
      placeholder: t('maya_tactic_form_key_message_placeholder'),
      required: isKeyMessageMandatory(selectedChannel as Channel),
      options: keyMessagesOptions || [],
      defaultValue: defaultFormValue.keyMessage,
      validation: TacticFormSchema.keyMessage,
    },
    parentEvent: {
      label: t('maya_tactic_form_parent_event_label'),
      name: 'parentEvent',
      checked: defaultFormValue.parentEvent,
      validation: TacticFormSchema.parentEvent,
      disabled: CEPApprovedAndAdvancedEditingDisabled,
    },
    approvedDocumentId: {
      label: t('maya_tactic_form_approved_document_id_label'),
      name: 'approvedDocumentId',
      placeholder: t('maya_tactic_form_approved_document_id_placeholder'),
      options: approvedDocumentIdOptions || [],
      defaultValue: defaultFormValue.approvedDocumentId,
      validation: TacticFormSchema.approvedDocumentId,
      freeOption: true,
      onChange: debouncedOnVeevaVaultFieldChange,
      loading: allVeevaVaultDocumentsLoading,
      loadingText: t('maya_tactic_form_veeva_vault_query_loading_text'),
    },
    dropMaterial: {
      label: t('maya_tactic_form_drop_material_label'),
      name: 'dropMaterial',
      placeholder: t('maya_tactic_form_drop_material_placeholder'),
      options: dropMaterialsOptions || [],
      defaultValue: defaultFormValue.dropMaterial,
      validation: TacticFormSchema.dropMaterials,
    },
    eventId: {
      label: t('maya_tactic_form_event_id_label'),
      name: 'eventId',
      placeholder: t('maya_tactic_form_event_id_placeholder'),
      defaultValue: defaultFormValue.eventId,
      validation: TacticFormSchema.eventId,
    },
    actionOrder: {
      label: t('maya_tactic_form_action_order_label'),
      name: 'actionOrder',
      placeholder: t('maya_tactic_form_action_order_placeholder'),
      type: 'number',
      required: true,
      defaultValue: defaultFormValue.actionOrder,
      validation: TacticFormSchema.actionOrder,
      disabled: CEPApprovedAndAdvancedEditingDisabled,
    },
    destinationUrl: {
      label: t('maya_tactic_form_destination_url_label'),
      name: 'destinationUrl',
      placeholder: t('maya_tactic_form_destination_url_placeholder'),
      defaultValue: defaultFormValue.destinationUrl,
      validation: TacticFormSchema.destinationUrl,
    },
    isKeyPerformanceIndicator: {
      label: t('maya_tactic_form_key_performance_indicator_label'),
      name: 'isKeyPerformanceIndicator',
      checked: defaultFormValue.isKeyPerformanceIndicator,
      validation: TacticFormSchema.isKeyPerformanceIndicator,
      disabled: CEPApprovedAndAdvancedEditingDisabled
        || (cepStateValue.tactics.some((tactic) => tactic.isKeyPerformanceIndicator)
        && !tacticStateValue.tactic?.isKeyPerformanceIndicator),
    },
  };

  return { tacticFormConfig };
};

export default useTacticFormConfig;
