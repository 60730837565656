import { useTranslation } from 'react-i18next';
import { mixed, number, string } from 'yup';

const useStrategicImperativeFormSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('form_errors_required');
  const invalidNumberLabel = t('form_errors_invalid_number');

  return {
    title: string()
      .required(requiredLabel),
    description: string(),
    therapeuticArea: mixed()
      .required(),
    year: number()
      .required(requiredLabel)
      .typeError(invalidNumberLabel),
  };
};

export default useStrategicImperativeFormSchema;
