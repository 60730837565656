import { useEffect, useState, useContext } from 'react';
import { useSnapshot } from 'valtio';
import useGetCepByIdQuery from 'basics/graphql/queries/getCepById';
import { CepStateType } from 'basics/types/maya.types';
import { ProductsContext } from 'contexts/products';
import cepState from 'states/cep.states';

const useCepConfigView = () => {
  const cepStateValue = useSnapshot(cepState) as CepStateType;

  const cepId = cepStateValue.cep?._id;

  const [loading, setLoading] = useState(true);

  const { data, loading: cepLoading } = useGetCepByIdQuery(cepId || null);
  const allProducts = useContext(ProductsContext);
  const productName = allProducts.find((product) => product.productExternalID === cepStateValue.cep?.product)?.productName || '';

  useEffect(() => {
    if (data) {
      setLoading(cepLoading);
    }
  }, [cepLoading, data, setLoading]);

  let date;
  if (data?.dateStart && data?.dateEnd) {
    date = `
      ${new Date(data.dateStart).toLocaleDateString('fr-FR')} 
      - 
      ${new Date(data.dateEnd).toLocaleDateString('fr-FR')}`;
  }

  return {
    cep: data,
    date,
    loading,
    productName,
  };
};

export default useCepConfigView;
