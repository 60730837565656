import { Box } from '@mui/material';
import { FC } from 'react';
import { AdminDashboardFiltersPropsType } from './AdminDashboardFilters.types';
import { adminDashboardFiltersForm } from 'components/AdminDashboardFilters/AdminDashboardFilters.style';
import FormContainer from 'components/FormContainer/FormContainer';
import { FormLayoutContainer } from 'components/FormLayout';
import DateRangePicker from 'yoda-ui/components/Form/DateRangePicker';
import MultiSelectYoda from 'yoda-ui/components/Form/SelectYoda/MultiSelectYoda';
import Loader from 'yoda-ui/components/Loader';
import { YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

const AdminDashboardFilters: FC<AdminDashboardFiltersPropsType> = ({
  adminDashboardFiltersConfig,
  providerFields,
  handleChangeTA,
  submitButtonConfig,
  resetButtonConfig,
  exportDataButtonConfig,
  allProducts,
}) => {
  return (
    <FormContainer
      providerFields={ providerFields }
      cancelButtonConfig={ resetButtonConfig }
      exportDataButtonConfig={ exportDataButtonConfig }
      submitButtonConfig={ submitButtonConfig }
    >
      <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } sx={ adminDashboardFiltersForm } >
        <Box>
          <MultiSelectYoda { ...adminDashboardFiltersConfig.countries } />
        </Box>
        <Box>
          <MultiSelectYoda { ...adminDashboardFiltersConfig.therapeuticArea } onChange={ handleChangeTA } />
        </Box>
        <Box>
          {
            allProducts.length === 0
              ? (
                <Box
                  display="flex"
                  justifyContent={ YodaJustifyContent.center }
                  position="absolute"
                  paddingTop={ YodaSpacing.small }
                  paddingBottom={ YodaSpacing.small }
                >
                  <Loader center size={ 1 } />
                </Box>
              )
              : null
          }
          <MultiSelectYoda
            { ...adminDashboardFiltersConfig.product }
            disabled={ allProducts.length === 0 || adminDashboardFiltersConfig.product.disabled }
          />
        </Box>
        <Box>
          <MultiSelectYoda { ...adminDashboardFiltersConfig.cepFunction } />
        </Box>
        <Box>
          <MultiSelectYoda { ...adminDashboardFiltersConfig.cepStatus } />
        </Box>
      </FormLayoutContainer>

      <Box>
        <DateRangePicker { ...adminDashboardFiltersConfig.date } />
      </Box>
    </FormContainer>
  );
};

export default AdminDashboardFilters;
