import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { DashboardFilter } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const COUNT_TACTICS = gql`
query CountTacticsType($filters: DashboardFilter!, $useAdminFilters: Boolean) {
  countTactics(filters: $filters, useAdminFilters: $useAdminFilters) {
    count
  }
}
`;

type ResultType = {
  countTactics: {
    count: number;
  };
};

type UseCountTactics = (filters?: DashboardFilter) => {
  countTacticsQuery: Function;
  loading: boolean;
  error?: ApolloError;
  tacticsData: unknown;
};

const useCountTacticsQuery: UseCountTactics = (filters?: DashboardFilter) => {
  const [countTacticsQuery, { data, loading, error }] = useLazyQuery<ResultType>(COUNT_TACTICS, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    variables: {
      filters,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'countTactics.ts - useCountTacticsQuery',
    });
  }

  return {
    countTacticsQuery,
    tacticsData: data?.countTactics.count,
    loading,
    error,
  };
};

export default useCountTacticsQuery;
