import { useEffect, useMemo, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetCustomerInitiativeByIdQuery from 'basics/graphql/queries/getCustomerInitiativeById';
import { ProductsContext } from 'contexts/products';

const useCustomerInitiativeView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const customerInitiative = useGetCustomerInitiativeByIdQuery(urlParams.get('id')).data;
  const allProducts = useContext(ProductsContext);
  const productName = allProducts.find((product) => product.productExternalID === customerInitiative?.product)?.productName || '';

  useEffect(() => {
    if (customerInitiative) {
      setLoading(false);
    }
  }, [customerInitiative, setLoading]);

  let date;
  if (customerInitiative?.dateStart && customerInitiative?.dateEnd) {
    date = `
      ${new Date(customerInitiative.dateStart).toLocaleDateString('fr-FR')} 
      - 
      ${new Date(customerInitiative.dateEnd).toLocaleDateString('fr-FR')}`;
  }

  return {
    customerInitiative,
    date,
    productName,
    loading,
    navigate,
  };
};

export default useCustomerInitiativeView;
