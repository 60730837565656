import { Box } from '@mui/material';
import { FC } from 'react';
import { dashboardFiltersForm } from 'components/DashboardFilters/DashboardFilters.style';
import { DashboardFiltersPropsType } from 'components/DashboardFilters/DashboardFilters.types';
import FormContainer from 'components/FormContainer/FormContainer';
import { FormLayoutContainer } from 'components/FormLayout';
import DateRangePicker from 'yoda-ui/components/Form/DateRangePicker';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import MultiSelectYoda from 'yoda-ui/components/Form/SelectYoda/MultiSelectYoda';
import Loader from 'yoda-ui/components/Loader';
import { YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

const DashboardFilters: FC<DashboardFiltersPropsType> = ({
  dashboardFiltersConfig,
  providerFields,
  handleChangeTA,
  handleChangeProduct,
  submitButtonConfig,
  resetButtonConfig,
  allProducts,
}) => {
  return (
    <FormContainer providerFields={ providerFields } cancelButtonConfig={ resetButtonConfig } submitButtonConfig={ submitButtonConfig } >
      <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } sx={ dashboardFiltersForm } >
        <Box>
          <MultiSelectYoda { ...dashboardFiltersConfig.countries } />
        </Box>
        <Box>
          <SelectYoda { ...dashboardFiltersConfig.therapeuticArea } onChange={ handleChangeTA } />
        </Box>
        <Box>
          {
            allProducts.length === 0
              ? (
                <Box
                  display="flex"
                  justifyContent={ YodaJustifyContent.center }
                  position="absolute"
                  paddingTop={ YodaSpacing.small }
                  paddingBottom={ YodaSpacing.small }
                >
                  <Loader center size={ 1 } />
                </Box>
              )
              : null
          }
          <MultiSelectYoda
            { ...dashboardFiltersConfig.product }
            onChange={ handleChangeProduct }
            disabled={ allProducts.length === 0 || dashboardFiltersConfig.product.disabled }
          />
        </Box>
        <Box>
          <MultiSelectYoda { ...dashboardFiltersConfig.cepFunction } />
        </Box>
        <Box>
          <MultiSelectYoda { ...dashboardFiltersConfig.cepStatus } />
        </Box>
      </FormLayoutContainer>

      <Box>
        <DateRangePicker { ...dashboardFiltersConfig.date } />
      </Box>
    </FormContainer>
  );
};

export default DashboardFilters;
