import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import biogenFunctionOptions from 'basics/options/biogenFunction.options';
import useCepStatusOptions from 'basics/options/cepStatus.options';
import initiativeCountryOptions from 'basics/options/country.options';
import useProductOptions from 'basics/options/product.options';
import useTherapeuticAreaOptions from 'basics/options/therapeuticArea.options';
import { CurrentUser } from 'basics/types/user.types';
import useAdminDashboardFiltersSchema from 'components/AdminDashboardFilters/AdminDashboardFilters.schema';
import { AdminDashboardFiltersConfigType } from 'components/AdminDashboardFilters/AdminDashboardFilters.types';
import { ProductsContext } from 'contexts/products';
import { DateRange } from 'yoda-ui/components/Form/DateRangePicker';

const useAdminDashboardFiltersConfig = (
  therapeuticArea: string[] | null,
  currentUserData: CurrentUser | null | undefined,
) => {
  const { t } = useTranslation();
  const currentTA = therapeuticArea ?? [''];
  const adminDashboardFiltersSchema = useAdminDashboardFiltersSchema();
  const allProducts = useContext(ProductsContext);

  let dateStart = currentUserData?.adminDashboardFilters?.dateStart;
  if (typeof dateStart === 'string') {
    dateStart = new Date(dateStart);
    dateStart = new Date(
      dateStart.getUTCFullYear(),
      dateStart.getUTCMonth(),
      dateStart.getUTCDate(),
      dateStart.getUTCHours(),
      dateStart.getUTCMinutes(),
      dateStart.getUTCSeconds(),
    );
  }

  let dateEnd = currentUserData?.adminDashboardFilters?.dateEnd;
  if (typeof dateEnd === 'string') {
    dateEnd = new Date(dateEnd);
    dateEnd = new Date(
      dateEnd.getUTCFullYear(),
      dateEnd.getUTCMonth(),
      dateEnd.getUTCDate(),
      dateEnd.getUTCHours(),
      dateEnd.getUTCMinutes(),
      dateEnd.getUTCSeconds(),
    );
  }

  const dateRange: DateRange = [
    dateStart || null,
    dateEnd || null,
  ];

  const adminDashboardFiltersConfig: AdminDashboardFiltersConfigType = {
    countries: {
      label: t('maya_dashboard_filters_countries_label'),
      name: 'countries',
      placeholder: t('maya_dashboard_filters_countries_placeholder'),
      required: true,
      defaultValue: currentUserData?.adminDashboardFilters?.countries || [],
      options: initiativeCountryOptions,
      validation: adminDashboardFiltersSchema.countries,
      enableSelectAll: true,
    },
    therapeuticArea: {
      label: t('maya_dashboard_filters_ta_label'),
      name: 'therapeuticArea',
      placeholder: t('maya_dashboard_filters_ta_placeholder'),
      options: useTherapeuticAreaOptions(allProducts),
      defaultValue: currentUserData?.adminDashboardFilters?.therapeuticArea || [],
      required: true,
      validation: adminDashboardFiltersSchema.therapeuticArea,
      enableSelectAll: true,
    },
    product: {
      label: t('maya_dashboard_filters_product_label'),
      name: 'product',
      placeholder: t('maya_dashboard_filters_product_placeholder'),
      options: useProductOptions(currentTA, allProducts),
      defaultValue: currentUserData?.adminDashboardFilters?.product || [],
      required: false,
      validation: adminDashboardFiltersSchema.product,
      enableSelectAll: true,
    },
    cepFunction: {
      label: t('maya_dashboard_filters_cep_function_label'),
      name: 'cepFunction',
      placeholder: t('maya_dashboard_filters_cep_function_placeholder'),
      options: biogenFunctionOptions,
      defaultValue: currentUserData?.adminDashboardFilters?.cepFunction || [],
      required: false,
      validation: adminDashboardFiltersSchema.cepFunction,
      enableSelectAll: true,
    },
    cepStatus: {
      label: t('maya_dashboard_filters_cep_status_label'),
      name: 'cepStatus',
      placeholder: t('maya_dashboard_filters_cep_status_placeholder'),
      options: useCepStatusOptions(),
      defaultValue: currentUserData?.adminDashboardFilters?.cepStatus || [],
      required: false,
      validation: adminDashboardFiltersSchema.cepStatus,
      enableSelectAll: true,
    },
    date: {
      startText: t('maya_admin_dashboard_filters_start_date_label'),
      endText: t('maya_admin_dashboard_filters_end_date_label'),
      name: 'date',
      defaultValue: dateRange,
      disablePast: false,
      startRequired: false,
      endRequired: false,
      validation: adminDashboardFiltersSchema.date,
    },
  };

  return { adminDashboardFiltersConfig };
};

export default useAdminDashboardFiltersConfig;
