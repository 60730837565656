import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import useCepFormConfigurationGroupSchema from './CepFormConfigurationGroup.schema';
import { CepFormConfigurationGroupConfigType } from './CepFormConfigurationGroup.types';
import { CepStatus } from 'basics/enums/maya.enums';
import biogenFunctionOptions from 'basics/options/biogenFunction.options';
import useCustomerInitiativeTypeOptions from 'basics/options/customerInitiative.options';
import customerTypeOptions from 'basics/options/customerType.options';
import useProductOptions from 'basics/options/product.options';
import SpecialitiesOptions from 'basics/options/specialty.options';
import useTherapeuticAreaOptions from 'basics/options/therapeuticArea.options';
import { cepToForm } from 'basics/transformers/Cep.transformer';
import { CepStateType, EventStateType } from 'basics/types/maya.types';
import { CurrentUser } from 'basics/types/user.types';
import { transformInputDateUTC } from 'basics/utils/dates/dates.utils';
import { ProductsContext } from 'contexts/products';
import { CustomerInitiative } from 'generated/maya.types';
import { configurationStepFields } from 'groups/CepFormConfigurationGroup/CepFormConfigurationGroup.constants';
import cepState from 'states/cep.states';
import eventState from 'states/event.states';

const useCepFormConfigurationGroupConfig = (
  customerInitiative: CustomerInitiative | undefined,
  user: CurrentUser | null | undefined,
  therapeuticArea: string | null,
  startDate: Date | null,
  endDate: Date | null,
  isTemplate: boolean,
) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const cepFormConfigurationGroupSchema = useCepFormConfigurationGroupSchema();
  const eventStateValue = useSnapshot(eventState) as EventStateType;
  const { eventId, event } = eventStateValue;
  const dateRange = transformInputDateUTC(event.dataRange || []);

  let startYear = startDate?.getFullYear();
  let endYear = endDate?.getFullYear();
  const currentYear = new Date().getFullYear();

  if (startYear && currentYear < startYear) {
    startYear = currentYear;
  }

  if (startYear && (!endYear || startYear === endYear)) {
    endYear = startYear + 1;
  }

  const optionsCI = useCustomerInitiativeTypeOptions(
    {
      isTemplate,
      years: startYear && endYear ? [startYear, endYear] : startYear ? [startYear] : undefined,
      countries: user?.country ? [user.country] : undefined,
      dateRange: eventId ? [dateRange.dateStart, dateRange.dateEnd] : undefined,
    },
  );

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const defaultFormValue = cepToForm(cepStateValue.cep, customerInitiative);

  const currentTA = therapeuticArea ?? defaultFormValue?.therapeuticArea ?? '';
  const isCepApproved = cepStateValue.cep?.status === CepStatus.APPROVED;
  const allProducts = useContext(ProductsContext);

  const cepFormConfigurationGroup: CepFormConfigurationGroupConfigType = {
    title: {
      label: t('maya_cep_step_configuration_name_label'),
      name: configurationStepFields.title,
      placeholder: t('maya_cep_step_configuration_name_label'),
      required: true,
      defaultValue: defaultFormValue.title,
      validation: cepFormConfigurationGroupSchema.title.trim(),
      disabled: isCepApproved,
    },
    date: {
      startText: t('maya_cep_step_configuration_date_start_label'),
      endText: t('maya_cep_step_configuration_date_end_label'),
      name: configurationStepFields.date,
      disablePast: false,
      validation: cepFormConfigurationGroupSchema.date,
      startRequired: true,
      endRequired: true,
      defaultValue: defaultFormValue.date,
      disabled: isCepApproved
        && (!flags.mayaBdcfcsd653ExtendEditingOfApprovedCep || !flags.mayaBdcfcsd1251EnableEditingEndDateOfApprovedCep),
      minDate: eventId && dateRange.dateStart ? dateRange.dateStart : undefined,
      maxDate: eventId && dateRange.dateEnd ? dateRange.dateEnd : undefined,
      disabledStartRange: isCepApproved
        && flags.mayaBdcfcsd653ExtendEditingOfApprovedCep && flags.mayaBdcfcsd1251EnableEditingEndDateOfApprovedCep,
    },
    ciDateStart: {
      label: '',
      name: configurationStepFields.ciDateStart,
      placeholder: '',
      required: true,
      defaultValue: customerInitiative?.dateStart,
      type: 'hidden',
    },
    ciDateEnd: {
      label: '',
      name: configurationStepFields.ciDateEnd,
      placeholder: '',
      required: true,
      defaultValue: customerInitiative?.dateEnd,
      type: 'hidden',
    },
    customerInitiative: {
      label: t('maya_cep_step_configuration_customer_initiative_label'),
      name: configurationStepFields.customerInitiative,
      placeholder: t('maya_cep_step_configuration_customer_initiative_label'),
      options: optionsCI,
      required: true,
      defaultValue: defaultFormValue.customerInitiative,
      validation: cepFormConfigurationGroupSchema.customerInitiative,
      disabled: isCepApproved,
    },
    therapeuticArea: {
      label: t('maya_cep_step_configuration_therapeutic_area_label'),
      name: configurationStepFields.therapeuticArea,
      placeholder: t('maya_cep_step_configuration_therapeutic_area_label'),
      options: useTherapeuticAreaOptions(allProducts),
      required: true,
      defaultValue: defaultFormValue.therapeuticArea || '',
      freeDefaultValue: true,
      validation: cepFormConfigurationGroupSchema.therapeuticArea,
      disabled: isCepApproved,
    },
    product: {
      label: t('maya_cep_step_configuration_product_label'),
      name: configurationStepFields.product,
      placeholder: t('maya_cep_step_configuration_product_label'),
      options: useProductOptions(currentTA, allProducts),
      required: true,
      defaultValue: defaultFormValue.product || '',
      validation: cepFormConfigurationGroupSchema.product,
      disabled: isCepApproved,
    },
    biogenFunction: {
      label: t('maya_cep_step_configuration_function_label'),
      name: configurationStepFields.biogenFunction,
      placeholder: t('maya_cep_step_configuration_function_placeholder'),
      options: biogenFunctionOptions,
      required: true,
      defaultValue: defaultFormValue.biogenFunction,
      validation: cepFormConfigurationGroupSchema.biogenFunction,
      disabled: isCepApproved,
    },
    kpi: {
      label: t('maya_cep_step_configuration_kpi_label'),
      name: configurationStepFields.kpi,
      placeholder: t('maya_cep_step_configuration_kpi_label'),
      required: true,
      defaultValue: defaultFormValue.kpi,
      validation: cepFormConfigurationGroupSchema.kpi.trim(),
      disabled: isCepApproved,
    },
    description: {
      label: t('maya_cep_step_configuration_description_label'),
      name: configurationStepFields.description,
      placeholder: t('maya_cep_step_configuration_description_placeholder'),
      required: false,
      multiline: true,
      rows: 4,
      defaultValue: defaultFormValue.description,
      validation: cepFormConfigurationGroupSchema.description,
      disabled: isCepApproved,
    },
    customerType: {
      label: t('maya_cep_step_configuration_customer_type_label'),
      name: configurationStepFields.customerType,
      placeholder: t('maya_cep_step_configuration_customer_type_label'),
      options: customerTypeOptions,
      required: true,
      defaultValue: defaultFormValue.customerType,
      validation: cepFormConfigurationGroupSchema.customerType,
      disabled: isCepApproved,
    },
    specialty: {
      label: t('maya_cep_step_configuration_specialty_label'),
      name: configurationStepFields.specialty,
      placeholder: t('maya_cep_step_configuration_specialty_label'),
      options: SpecialitiesOptions,
      defaultValue: defaultFormValue.specialty,
      required: true,
      validation: cepFormConfigurationGroupSchema.specialties,
      disabled: isCepApproved,
    },
  };

  return {
    cepFormConfigurationGroup,
  };
};

export default useCepFormConfigurationGroupConfig;
