import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useStrategicImperativeFormSchema from './StrategicImperativeForm.schema';
import { StrategicImperativeFormConfigType } from './StrategicImperativeForm.types';
import useTherapeuticAreaOptions from 'basics/options/therapeuticArea.options';
import useStrategicImperativeYearOptions from 'components/StrategicImperativeForm/strategicImperativeYear.options';
import { ProductsContext } from 'contexts/products';
import { StrategicImperative } from 'generated/maya.types';

const useStrategicImperativeFormConfig = (strategicImperative: StrategicImperative | undefined) => {
  const { t } = useTranslation();
  const strategicImperativeFormSchema = useStrategicImperativeFormSchema();
  const allProducts = useContext(ProductsContext);
  const strategicImperativeFormConfig: StrategicImperativeFormConfigType = {
    title: {
      label: t('maya_strategic_imperative_form_title_label'),
      name: 'title',
      placeholder: t('maya_strategic_imperative_form_title_placeholder'),
      required: true,
      defaultValue: strategicImperative?.title.trim() || '',
      validation: strategicImperativeFormSchema.title,
    },
    description: {
      label: t('maya_strategic_imperative_form_description_label'),
      name: 'description',
      placeholder: t('maya_strategic_imperative_form_description_label'),
      required: false,
      defaultValue: strategicImperative?.description || '',
      multiline: true,
      rows: 4,
      validation: strategicImperativeFormSchema.description,
    },
    year: {
      label: t('maya_strategic_imperative_form_year_label'),
      name: 'year',
      placeholder: t('maya_strategic_imperative_form_year_label'),
      options: useStrategicImperativeYearOptions(),
      required: true,
      defaultValue: strategicImperative?.year || '',
      validation: strategicImperativeFormSchema.year,
    },
    therapeuticArea: {
      label: t('maya_strategic_imperative_form_therapeutic_area_label'),
      name: 'therapeuticArea',
      placeholder: t('maya_strategic_imperative_form_therapeutic_area_placeholder'),
      options: useTherapeuticAreaOptions(allProducts),
      defaultValue: strategicImperative?.therapeuticArea || '',
      freeDefaultValue: true,
      required: true,
      validation: strategicImperativeFormSchema.therapeuticArea,
    },
  };

  return { strategicImperativeFormConfig };
};

export default useStrategicImperativeFormConfig;
