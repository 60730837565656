import { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import useCustomerInitiativeFormSchema from './CustomerInitiativeForm.schema';
import { CustomerInitiativeFormConfigType } from './CustomerInitiativeForm.types';
import { mayaCiTemplateCreateRoute, mayaCiTemplateEditRoute } from 'basics/constants/routes.constants';
import { CiStatus } from 'basics/enums/maya.enums';
import biogenFunctionOptions from 'basics/options/biogenFunction.options';
import initiativeCountriesAndRegionOptions from 'basics/options/countriesAndRegion.options';
import initiativeCountryOptions from 'basics/options/country.options';
import useProductOptions from 'basics/options/product.options';
import useStrategicImperativeOptions from 'basics/options/strategicImperative.options';
import useTherapeuticAreaOptions from 'basics/options/therapeuticArea.options';
import { ciToForm, eventToCi } from 'basics/transformers/CustomerInitiative.transformer';
import { EventStateType } from 'basics/types/maya.types';
import { CurrentUser } from 'basics/types/user.types';
import { transformInputDateUTC } from 'basics/utils/dates/dates.utils';
import { ProductsContext } from 'contexts/products';
import { CustomerInitiative, StrategicImperative } from 'generated/maya.types';
import eventState from 'states/event.states';

const useCustomerInitiativeFormConfig = (
  customerInitiative: CustomerInitiative | undefined,
  strategicImperative: StrategicImperative | undefined,
  therapeuticArea: string | null,
  startDate: Date | null,
  currentUserData: CurrentUser | null | undefined,
) => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentURL = location.pathname;
  const templateRoute = [mayaCiTemplateCreateRoute, mayaCiTemplateEditRoute];
  const countryOptions = templateRoute.includes(currentURL) ? initiativeCountriesAndRegionOptions : initiativeCountryOptions;
  const isTemplate = templateRoute.includes(currentURL);
  const customerInitiativeFormSchema = useCustomerInitiativeFormSchema(isTemplate);
  const eventStateValue = useSnapshot(eventState) as EventStateType;
  const dateRange = transformInputDateUTC(eventStateValue.event.dataRange || []);
  const allProducts = useContext(ProductsContext);

  const event = useMemo(
    () => eventToCi(eventStateValue.event),
    [eventStateValue.event],
  );
  const defaultFormValue = useMemo(
    () => ciToForm(eventStateValue.eventId ? event : customerInitiative, allProducts, strategicImperative),
    [eventStateValue.eventId, event, customerInitiative, allProducts, strategicImperative],
  );

  const strategicImperativesOptions = useStrategicImperativeOptions(
    { _id: strategicImperative?._id, year: startDate?.getFullYear() },
  );

  const extraErrorMessage = useMemo(() => {
    if (startDate?.getFullYear() && !strategicImperativesOptions.options.length && !strategicImperativesOptions.loading) {
      return t('maya_strategic_imperative_empty_error', { year: startDate?.getFullYear() });
    }

    return '';
  }, [startDate, strategicImperativesOptions.options.length, strategicImperativesOptions.loading, t]);

  const currentTA = therapeuticArea ?? customerInitiative?.therapeuticArea ?? '';

  const customerInitiativeFormConfig: CustomerInitiativeFormConfigType = {
    title: {
      label: t('maya_customer_initiative_form_title_label'),
      name: 'title',
      placeholder: t('maya_customer_initiative_form_title_placeholder'),
      required: true,
      defaultValue: defaultFormValue.title,
      validation: customerInitiativeFormSchema.title.trim(),
      disabled: customerInitiative?.status === CiStatus.ACTIVE && !customerInitiative?.isTemplate,
    },
    description: {
      label: t('maya_customer_initiative_form_description_label'),
      name: 'description',
      placeholder: t('maya_customer_initiative_form_description_placeholder'),
      required: true,
      defaultValue: defaultFormValue.description,
      multiline: true,
      validation: customerInitiativeFormSchema.description.trim(),
      rows: 4,
      disabled: customerInitiative?.status === CiStatus.ACTIVE && !customerInitiative?.isTemplate,
    },
    date: {
      startText: t('maya_customer_initiative_date_start_label'),
      endText: t('maya_customer_initiative_date_end_label'),
      name: 'date',
      disablePast: false,
      validation: customerInitiativeFormSchema.date,
      startRequired: true,
      endRequired: true,
      defaultValue: defaultFormValue.date,
      disabled: customerInitiative?.status === CiStatus.ACTIVE && !customerInitiative?.isTemplate,
      minDate: eventStateValue.eventId && dateRange.dateStart ? dateRange.dateStart : undefined,
      maxDate: eventStateValue.eventId && dateRange.dateEnd ? dateRange.dateEnd : undefined,
      extraErrorMessage,
    },
    country: {
      label: t('maya_customer_initiative_form_country_label'),
      name: 'country',
      placeholder: t('maya_customer_initiative_form_country_placeholder'),
      defaultValue: defaultFormValue.country || currentUserData?.country,
      options: countryOptions,
      required: true,
      validation: customerInitiativeFormSchema.country,
      disabled: customerInitiative?.status === CiStatus.ACTIVE && !customerInitiative?.isTemplate,
    },
    therapeuticArea: {
      label: t('maya_customer_initiative_form_therapeutic_area_label'),
      name: 'therapeuticArea',
      placeholder: t('maya_customer_initiative_form_therapeutic_area_placeholder'),
      defaultValue: defaultFormValue.therapeuticArea,
      freeDefaultValue: true,
      options: useTherapeuticAreaOptions(allProducts),
      required: true,
      validation: customerInitiativeFormSchema.therapeuticArea,
      disabled: customerInitiative?.status === CiStatus.ACTIVE && !customerInitiative?.isTemplate,
    },
    strategicImperative: {
      label: t('maya_customer_initiative_form_strategic_imperative_label'),
      name: 'strategicImperative',
      placeholder: t('actions_select_placeholder'),
      options: strategicImperativesOptions.options,
      defaultValue: defaultFormValue.strategicImperative,
      required: true,
      validation: customerInitiativeFormSchema.strategicImperative,
      disabled: customerInitiative?.status === CiStatus.ACTIVE && !customerInitiative?.isTemplate,
    },
    product: {
      label: t('maya_customer_initiative_form_product_label'),
      name: 'product',
      placeholder: t('maya_customer_initiative_form_product_placeholder'),
      options: useProductOptions(currentTA, allProducts),
      defaultValue: defaultFormValue.product,
      required: true,
      validation: customerInitiativeFormSchema.product,
      disabled: customerInitiative?.status === CiStatus.ACTIVE && !customerInitiative?.isTemplate,
    },
    problemStatement: {
      label: t('maya_customer_initiative_form_problem_statement_label'),
      name: 'problemStatement',
      placeholder: t('maya_customer_initiative_form_problem_statement_placeholder'),
      required: false,
      defaultValue: defaultFormValue.problemStatement,
      multiline: true,
      rows: 4,
      validation: customerInitiativeFormSchema.problemStatement,
      disabled: customerInitiative?.status === CiStatus.ACTIVE && !customerInitiative?.isTemplate,
    },
    solutionCoreStory: {
      label: t('maya_customer_initiative_form_solution_core_story_label'),
      name: 'solutionCoreStory',
      placeholder: t('maya_customer_initiative_form_solution_core_story_placeholder'),
      required: true,
      defaultValue: defaultFormValue.solutionCoreStory,
      multiline: true,
      rows: 4,
      validation: customerInitiativeFormSchema.solutionCoreStory.trim(),
      disabled: customerInitiative?.status === CiStatus.ACTIVE && !customerInitiative?.isTemplate,
    },
    functionalOwner: {
      label: t('maya_customer_initiative_form_functional_owner_label'),
      name: 'functionalOwner',
      placeholder: t('maya_customer_initiative_form_functional_owner_placeholder'),
      options: biogenFunctionOptions,
      defaultValue: defaultFormValue.functionalOwner,
      required: true,
      validation: customerInitiativeFormSchema.functionalOwner,
      disabled: customerInitiative?.status === CiStatus.ACTIVE && !customerInitiative?.isTemplate,
    },
    functionsInvolved: {
      label: t('maya_customer_initiative_form_functions_involved_label'),
      name: 'functionsInvolved',
      placeholder: t('actions_select_placeholder'),
      options: biogenFunctionOptions,
      required: true,
      defaultValue: defaultFormValue.functionsInvolved,
      validation: customerInitiativeFormSchema.functionsInvolved,
      disabled: customerInitiative?.status === CiStatus.ACTIVE && !customerInitiative?.isTemplate,
    },
  };

  return { customerInitiativeFormConfig };
};

export default useCustomerInitiativeFormConfig;
