import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { ProductsContext } from 'contexts/products';
import cepState from 'states/cep.states';

const useSideFormDetails = () => {
  const { t } = useTranslation();

  const cepStateValue = useSnapshot(cepState);

  const allProducts = useContext(ProductsContext);
  const productName = allProducts.find((product) => product.productExternalID === cepStateValue.cep?.product)?.productName || '';

  let date;

  if (cepStateValue.cep?.dateStart && cepStateValue.cep?.dateEnd) {
    date = `
      ${new Date(cepStateValue.cep.dateStart).toLocaleDateString('fr-FR')} 
      - 
      ${new Date(cepStateValue.cep.dateEnd).toLocaleDateString('fr-FR')}`;
  }

  return {
    date,
    cepStateValue,
    productName,
    t,
  };
};

export default useSideFormDetails;
