import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProductType } from '../types/products.types';
import { AllValidTherapeuticArea, BiogenTherapeuticArea, TherapeuticAreaAlias } from 'basics/enums/biogen.enums';
import { SelectOption } from 'basics/options/options.types';

const getStaticTherapeuticAreaOptions = (): SelectOption[] => {
  return Object.values(BiogenTherapeuticArea).map((ta) => ({ id: ta, label: ta }));
};

const getDynamicTherapeuticAreaOptions = (products: ProductType[]): SelectOption[] => {
  const therapeuticAreas = [...new Set(products.map((product) => product.therapeuticArea))];

  return therapeuticAreas.map((therapeuticArea) => ({
    id: therapeuticArea as string,
    label: therapeuticArea === AllValidTherapeuticArea.ALZHEIMER ? TherapeuticAreaAlias.ALZ : therapeuticArea as string,
  }));
};

const useTherapeuticAreaOptions = (products: ProductType[]): SelectOption[] => {
  const flags = useFlags();

  return flags.mayaBdcfcsd2599EnableDynamicProductDisplay
    ? getDynamicTherapeuticAreaOptions(products)
    : getStaticTherapeuticAreaOptions();
};

export default useTherapeuticAreaOptions;
