import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import {
  titleGroupStyle,
  titleBoxStyle,
  suffixBoxStyle,
} from '../TacticForm.style';
import { TacticFormTypes } from 'components/TacticForm/TacticForm.types';
import Box from 'yoda-ui/components/Box';
import CheckBoxYoda from 'yoda-ui/components/Form/CheckBoxYoda';
import DateRangePicker from 'yoda-ui/components/Form/DateRangePicker';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

export const BroadcastEmail: FC<TacticFormTypes> = ({ tacticFormConfig }) => {
  const flags = useFlags();

  return (
    <>
      {
        flags.mayaAkma297EnableSuffixForTacticName ? (
          <Box sx={ titleGroupStyle }>
            <Box sx={ titleBoxStyle }>
              <TextYoda { ...tacticFormConfig.title } />
            </Box>
            <Box sx={ suffixBoxStyle }>
              <TextYoda { ...tacticFormConfig.titleSuffix } />
            </Box>
          </Box>
        ) : (
          <Box marginBottom={ YodaSpacing.large }>
            <TextYoda { ...tacticFormConfig.title } />
          </Box>
        )
      }
      <Box marginBottom={ YodaSpacing.large }>
        <DateRangePicker { ...tacticFormConfig.date } />
      </Box>
      <Box marginBottom={ YodaSpacing.xSmall }>
        <CheckBoxYoda { ...tacticFormConfig.isKeyPerformanceIndicator } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <CheckBoxYoda { ...tacticFormConfig.mandatory } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...tacticFormConfig.actionOrder } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.previousTactic } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.executingTeam } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.assetId } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...tacticFormConfig.assetName } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.callToAction } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...tacticFormConfig.clickUrl } />
      </Box>
    </>
  );
};
