import { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CiStatus } from 'basics/enums/maya.enums';
import useGetStrategicImperativeByIdQuery from 'basics/graphql/queries/getStrategicImperativeById';
import { SelectOption } from 'basics/options/options.types';
import { ciToBE } from 'basics/transformers/CustomerInitiative.transformer';
import { ProductsOptionType } from 'basics/types/products.types';
import useCustomerInitiativeFormConfig from 'components/CustomerInitiativeForm/CustomerInitiativeForm.config';
import {
  CiFormCanceltCallbackType,
  CiFormSubmitCallbackType,
} from 'components/CustomerInitiativeForm/CustomerInitiativeForm.types';
import { ProductsContext } from 'contexts/products';
import { useCurrentUserState } from 'contexts/user';
import { CustomerInitiative, StrategicImperative } from 'generated/maya.types';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useCustomerInitiativeCreate = (
  parentStrategicImperative: StrategicImperative | undefined,
  customerInitiative: CustomerInitiative | undefined,
  submitCallback: CiFormSubmitCallbackType,
  cancelCallback: CiFormCanceltCallbackType,
) => {
  const { t } = useTranslation();

  const { getValues, providerFields, resetForm, useWatchForm, setValue, useWatchField, setFieldShowError } = useYodaCreateForm();
  const { isValid } = useWatchForm();
  const { user } = useCurrentUserState();

  const [loading, setLoading] = useState(true);
  const [siLoading, setSiLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSi, setselectedSi] = useState<string | null>(null);
  const [selectedTA, setSelectedTA] = useState<string | null>(null);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const allProducts = useContext(ProductsContext);

  const { customerInitiativeFormConfig } = useCustomerInitiativeFormConfig(
    customerInitiative,
    parentStrategicImperative,
    selectedTA,
    selectedStartDate,
    user,
  );

  if (customerInitiative) {
    const fieldsName = Object.keys(customerInitiativeFormConfig);
    setFieldShowError(true, fieldsName);
  }

  useEffect(() => {
    if (customerInitiativeFormConfig?.strategicImperative?.options.length) {
      setLoading(false);
    }
  }, [customerInitiativeFormConfig?.strategicImperative?.options, setLoading]);

  const currentStrategicImperative = useGetStrategicImperativeByIdQuery(selectedSi).data;
  useEffect(() => {
    if (currentStrategicImperative) {
      setValue(customerInitiativeFormConfig.therapeuticArea.name, currentStrategicImperative?.therapeuticArea);
      setSiLoading(false);
    }
  }, [currentStrategicImperative, customerInitiativeFormConfig.therapeuticArea.name, setValue]);

  const therapeuticAreaKey = useWatchField(customerInitiativeFormConfig.therapeuticArea.name)?.value;
  useEffect(() => {
    setSelectedTA(therapeuticAreaKey);
  }, [therapeuticAreaKey]);

  const date = useWatchField(customerInitiativeFormConfig.date.name)?.value;
  useEffect(() => {
    setSelectedStartDate(date ? date[0] : null);
  }, [date]);

  const handleChangeStrategicImperative = useCallback((event: SyntheticEvent<Element, Event>, value: SelectOption) => {
    setSiLoading(!!value);
    setselectedSi(value?.id as string);
  }, [setselectedSi]);

  const handleChangeTA = useCallback(() => {
    setValue(customerInitiativeFormConfig.product.name, '');
  }, [setValue, customerInitiativeFormConfig]);

  const handleChangeProduct = useCallback((event: SyntheticEvent<Element, Event>, selectedProduct: ProductsOptionType) => {
    if (selectedProduct) {
      const therapeuticAreaValue = selectedProduct.therapeuticArea;
      setValue(customerInitiativeFormConfig.therapeuticArea.name, therapeuticAreaValue);
    }
  }, [setValue, customerInitiativeFormConfig]);

  const handleCancelCustomerInitiativeForm = () => {
    resetForm();
    cancelCallback();
  };

  const handleSaveCustomerInitiativeForm = async () => {
    setIsSubmitting(true);
    const formValues = getValues();
    const formattedCI = ciToBE(formValues);

    await submitCallback(formattedCI);

    setIsSubmitting(false);
  };

  const cancelButtonConfig = {
    buttonProps: {
      onClick: handleCancelCustomerInitiativeForm,
      buttonType: ButtonType.secondary,
    },
    label: t('actions_cancel_label'),
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: handleSaveCustomerInitiativeForm,
      buttonType: ButtonType.primary,
      disabled: customerInitiative?.status === CiStatus.ACTIVE || !isValid || isSubmitting,
    },
    label: t('actions_save_label'),
    loading: isSubmitting,
  };

  return {
    cancelButtonConfig,
    customerInitiativeFormConfig,
    handleChangeProduct,
    handleChangeStrategicImperative,
    handleChangeTA,
    loading,
    providerFields,
    siLoading,
    submitButtonConfig,
    allProducts,
  };
};

export default useCustomerInitiativeCreate;
