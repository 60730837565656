import { useEffect } from 'react';
import useGetTargetsReachQuery from 'basics/graphql/queries/getTargetsReach';
import { DashboardFilter } from 'generated/maya.types';

const useTargetsReachWidget = (filters?: DashboardFilter, useAdminFilters = false) => {
  const { getTargetsReachQuery, targetsReach, loading, error } = useGetTargetsReachQuery();

  useEffect(() => {
    if (filters) {
      getTargetsReachQuery({ variables: { filters, useAdminFilters } });
    }
  }, [filters, getTargetsReachQuery, useAdminFilters]);

  const TargetReachPercent = targetsReach !== undefined ? `${targetsReach}%` : targetsReach;

  return {
    value: TargetReachPercent,
    loading,
    error,
  };
};

export default useTargetsReachWidget;
