import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AdminDashboardFilters from '../AdminDashboardFilters/AdminDashboardFilters';
import useAdminDashboardFilters from '../AdminDashboardFilters/AdminDashboardFilters.hook';
import {
  adminDashboardGridList,
  adminDashboardSummaryStats,
} from './AdminDashboard.style';
import CepAdminDashBoardList from './CepAdminDashboardGridlist/CepAdminDashboardList';
import CiAdminDashboardList from './CiAdminDashboardGridlist/CiAdminDashboardList';
import ContentCard from 'components/ContentCard';
import CountCepWidget from 'components/CountWidgets/CountCepWidget/CountCepWidget';
import CountCiWidget from 'components/CountWidgets/CountCiWidget/CountCiWidget';
import CountTacticsWidget from 'components/CountWidgets/CountTacticsWidget/CountTacticsWidget';
import CountTargetsWidget from 'components/CountWidgets/CountTargetsWidget/CountTargetsWidget';
import TargetsEngagementWidget from 'components/CountWidgets/TargetsEngagementWidget/TargetsEngagementWidget';
import TargetsReachWidget from 'components/CountWidgets/TargetsReachWidget/TargetsReachWidget';
import Heading from 'components/Heading';
import Box from 'yoda-ui/components/Box';

const AdminDashboard: FC = () => {
  const { t } = useTranslation();
  const {
    providerFields,
    adminDashboardFiltersConfig,
    handleChangeTA,
    submitButtonConfig,
    resetButtonConfig,
    exportDataButtonConfig,
    filters,
    allProducts,
  } = useAdminDashboardFilters();

  return (
    <Box flexDirection="column">
      <Heading>
        { t('maya_dashboard_title') }
      </Heading>

      <AdminDashboardFilters
        providerFields={ providerFields }
        adminDashboardFiltersConfig={ adminDashboardFiltersConfig }
        handleChangeTA={ handleChangeTA }
        submitButtonConfig={ submitButtonConfig }
        resetButtonConfig={ resetButtonConfig }
        exportDataButtonConfig={ exportDataButtonConfig }
        allProducts={ allProducts }
      />

      <Box>
        <ContentCard>
          <Heading>
            { t('maya_dashboard_title_summary_stats_title') }
          </Heading>

          <Box sx={ adminDashboardSummaryStats }>
            <CountCiWidget filters={ filters } useAdminFilters={ true } />
            <CountCepWidget filters={ filters } useAdminFilters={ true } />
            <CountTacticsWidget filters={ filters } useAdminFilters={ true } />
            <CountTargetsWidget filters={ filters } useAdminFilters={ true } />
            <TargetsEngagementWidget filters={ filters } useAdminFilters={ true } />
            <TargetsReachWidget filters={ filters } useAdminFilters={ true } />
          </Box>
        </ContentCard>

        <Box sx={ adminDashboardGridList }>
          <CiAdminDashboardList filters={ filters } useAdminFilters={ true } />
          <CepAdminDashBoardList filters={ filters } useAdminFilters={ true } />
        </Box>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
