import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import {
  mayaCepCreateRoute,
  mayaCepDuplicateRoute,
  mayaCepEditRoute,
  mayaCepListRoute,
  mayaCustomerInitiativeCreateRoute,
  mayaCustomerInitiativeListRoute,
  mayaImportExportRoute,
  mayaCiTemplateListRoute,
  mayaStrategicImperativeCreateRoute,
  mayaStrategicImperativeListRoute,
  mayaCiTemplateCreateRoute,
  mayaCustomerInitiativeEditRoute,
  mayaCustomerInitiativeDuplicateRoute,
  mayaCiTemplateEditRoute,
  mayaCepTemplateListRoute,
  mayaCepTemplateCreateRoute,
  mayaCepTemplateEditRoute,
  mayaCepTrashedListRoute,
  mayaStrategicImperativeDuplicateRoute,
  mayaStrategicImperativeEditRoute,
  mayaAdminDashboardRoute,
} from 'basics/constants/routes.constants';
import { MayaMenuEntryConfig } from 'components/MayaMenu/MayaMenu.types';

const useMayaMenuConfig = (
  ciYear: number[],
  siYear: number[],
) => {
  const { t } = useTranslation();
  const flags = useFlags();

  const strategicImperativePath = () => {
    const siChildren: MayaMenuEntryConfig[] = [];

    if (ciYear) {
      siYear.forEach(
        (year) => siChildren.push({
          id: `strategicImperativeList${year}`,
          title: t('maya_main_menu_si_by_year_label', { year }),
          targetRoute: `${mayaStrategicImperativeListRoute}?year=${year}`,
          enable: true,
          activeRoute: [
            `${mayaStrategicImperativeListRoute}?year=${year}`,
          ],
        }),
      );
    }

    return siChildren;
  };

  const ciPath = () => {
    const ciChildren: MayaMenuEntryConfig[] = [];

    if (siYear) {
      ciYear.forEach(
        (year) => ciChildren.push({
          id: `customerInitiativelist${year}`,
          title: t('maya_main_menu_ci_by_year_label', { year }),
          targetRoute: `${mayaCustomerInitiativeListRoute}?year=${year}`,
          enable: true,
          activeRoute: [
            `${mayaCustomerInitiativeListRoute}?year=${year}`,
          ],
        }),
      );
    }

    return ciChildren;
  };

  const configMenu: MayaMenuEntryConfig[] = [
    {
      id: 'strategicImperative',
      title: t('maya_main_menu_strategic_imperative_label'),
      targetRoute: mayaStrategicImperativeListRoute,
      enable: flags.mayaBiogenlinc3715ShowStrategicImperatives,
      activeRoute: [
        mayaStrategicImperativeListRoute,
        mayaStrategicImperativeCreateRoute,
        mayaStrategicImperativeDuplicateRoute,
        mayaStrategicImperativeEditRoute,
      ],
      childrens: strategicImperativePath(),
    },
    {
      id: 'customerInitiative',
      title: t('maya_main_menu_ci'),
      enable: true,
      activeRoute: [
        mayaCustomerInitiativeListRoute,
        mayaCustomerInitiativeCreateRoute,
        mayaCustomerInitiativeEditRoute,
        mayaCustomerInitiativeDuplicateRoute,
        mayaCiTemplateListRoute,
        mayaCiTemplateCreateRoute,
        mayaCiTemplateEditRoute,
      ],
      childrens: [
        ...ciPath(),
        {
          id: 'customerInitiativeTemplates',
          title: t('maya_menu_ci_tpl_label'),
          targetRoute: mayaCiTemplateListRoute,
          enable: flags.mayaBiogenlinc3129EnableCiTemplates,
          activeRoute: [
            mayaCiTemplateListRoute,
            mayaCiTemplateCreateRoute,
            mayaCiTemplateEditRoute,
          ],
        },
      ],
    },
    {
      id: 'customerEngagementPlanning',
      title: t('maya_main_menu_cep'),
      enable: true,
      activeRoute: [
        mayaCepListRoute,
        mayaCepCreateRoute,
        mayaCepEditRoute,
        mayaCepDuplicateRoute,
        mayaCepTemplateListRoute,
        mayaCepTemplateCreateRoute,
        mayaCepTemplateEditRoute,
        mayaCepTrashedListRoute,
      ],
      childrens: [
        {
          id: 'customerEngagementPlanningList',
          title: t('maya_main_menu_cep_list_label'),
          targetRoute: mayaCepListRoute,
          enable: true,
          activeRoute: [
            mayaCepListRoute,
            mayaCepCreateRoute,
            mayaCepEditRoute,
            mayaCepDuplicateRoute,
          ],
        },
        {
          id: 'cepTemplates',
          title: t('maya_menu_cep_tpl_label'),
          targetRoute: mayaCepTemplateListRoute,
          enable: flags.mayaBiogenlinc3129EnableCepTemplates,
          activeRoute: [
            mayaCepTemplateListRoute,
            mayaCepTemplateCreateRoute,
            mayaCepTemplateEditRoute,
          ],
        },
        {
          id: 'customerEngagementPlanningTrashed',
          title: t('maya_menu_cep_trashed_label'),
          targetRoute: mayaCepTrashedListRoute,
          enable: flags.mayaBdcfcsd1251EnableCepTrashBinPage,
          activeRoute: [
            mayaCepTrashedListRoute,
          ],
        },
      ],
    },
    {
      id: 'aopImportExport',
      title: t('maya_main_menu_import_export_label'),
      targetRoute: mayaImportExportRoute,
      enable: flags.mayaEnableAopImportBiogenlinc32,
      activeRoute: [mayaImportExportRoute],
    },
    {
      id: 'dashboard',
      title: t('maya_main_menu_dashboard_label'),
      targetRoute: mayaAdminDashboardRoute,
      enable: flags.mayaBdcfcsd2528EnableDashboardPage,
      activeRoute: [mayaAdminDashboardRoute],
    },
  ];

  return { configMenu };
};

export default useMayaMenuConfig;
