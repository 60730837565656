import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import InfoTag from 'components/InfoTag/InfoTag';
import { CepViewPropsType } from 'routes/AuthenticatedApp/CepView/CepView.types';
import useCepConfigView from 'routes/AuthenticatedApp/CepView/Steps/CepConfigView/CepConfigView.hook';
import { sideContainerStyle } from 'routes/AuthenticatedApp/CepView/Steps/CepConfigView/CepConfigView.styles';
import Box from 'yoda-ui/components/Box';
import Loader from 'yoda-ui/components/Loader';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const CepConfigView: FC<CepViewPropsType> = () => {
  const { t } = useTranslation();
  const {
    cep,
    date,
    loading,
    productName,
  } = useCepConfigView();

  return (
    <>
      {
        loading
          ? (
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
              <Loader center />
            </Box>
          )
          : (
            <Box>
              <Box
                sx={ sideContainerStyle.firstBox }
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                padding={ YodaSpacing.medium }
              >
                <Box marginBottom={ YodaSpacing.xxSmall } marginTop={ YodaSpacing.xxSmall }>
                  <Box>
                    <InfoTag
                      title={ t('maya_cep_view_title') }
                      field={ cep?.title }
                    />
                    <InfoTag
                      title={ t('maya_cep_view_ci_title') }
                      field={ cep?.customerInitiative.title }
                    />
                    <InfoTag
                      title={ t('maya_cep_view_date') }
                      field={ date }
                    />
                    <InfoTag
                      title={ t('maya_cep_view_therapeutic_area') }
                      field={ cep?.therapeuticArea }
                    />
                    <InfoTag
                      title={ t('maya_cep_view_product') }
                      field={ productName }
                    />
                    <InfoTag
                      title={ t('maya_cep_view_functional_owner') }
                      field={ cep?.biogenFunction }
                    />
                    <InfoTag
                      title={ t('maya_cep_view_kpi') }
                      field={ cep?.kpi }
                    />
                    <InfoTag
                      title={ t('maya_cep_view_description') }
                      field={ cep?.description ? cep.description : '' }
                    />
                    <InfoTag
                      title={ t('maya_cep_view_customer_type') }
                      field={ cep?.customerType.toString() }
                    />
                    <InfoTag
                      title={ t('maya_cep_view_specialty') }
                      field={ cep?.specialty.toString() }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )
      }
    </>
  );
};

export default CepConfigView;

