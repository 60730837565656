import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import {
  actionButtonStyle,
  tacticGroupBoxStyle,
  tacticGroupCircularProgressStyle,
  tacticGroupCircularProgressSize,
  tacticGroupButtonBoxStyle,
  titleGroupStyle,
  titleBoxStyle,
  suffixBoxStyle,
} from '../TacticForm.style';
import { TacticFormTypes } from 'components/TacticForm/TacticForm.types';
import TooltipList from 'components/TooltipList/TooltipList';
import { ActionButton } from 'yoda-ui/components/BiogenLincDS/Buttons';
import Box from 'yoda-ui/components/Box';
import CircularProgress from 'yoda-ui/components/CircularProgress';
import CheckBoxYoda from 'yoda-ui/components/Form/CheckBoxYoda';
import DateRangePicker from 'yoda-ui/components/Form/DateRangePicker';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import { YodaFontSize, YodaSpacing } from 'yoda-ui/yodaTheme';

export const ApprovedEmail: FC<TacticFormTypes> = ({ tacticFormConfig, tacticGroupLoading, showTacticGroup }) => {
  const flags = useFlags();

  return (
    <>
      {
        flags.mayaAkma297EnableSuffixForTacticName ? (
          <Box sx={ titleGroupStyle }>
            <Box sx={ titleBoxStyle }>
              <TextYoda { ...tacticFormConfig.title } />
            </Box>
            <Box sx={ suffixBoxStyle }>
              <TextYoda { ...tacticFormConfig.titleSuffix } />
            </Box>
          </Box>
        ) : (
          <Box marginBottom={ YodaSpacing.large }>
            <TextYoda { ...tacticFormConfig.title } />
          </Box>
        )
      }
      <Box marginBottom={ YodaSpacing.large }>
        <DateRangePicker { ...tacticFormConfig.date } />
      </Box>
      <Box marginBottom={ YodaSpacing.xSmall }>
        <CheckBoxYoda { ...tacticFormConfig.isKeyPerformanceIndicator } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <CheckBoxYoda { ...tacticFormConfig.mandatory } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...tacticFormConfig.actionOrder } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.previousTactic } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.executingTeam } />
      </Box>
      {
        !flags.mayaBdcfcsd1585EnableTacticGroup || !showTacticGroup ? (
          <Box marginBottom={ YodaSpacing.large }>
            <SelectYoda { ...tacticFormConfig.parentTactic } />
          </Box>
        ) : null
      }
      {
        flags.mayaBdcfcsd1585EnableTacticGroup && showTacticGroup
          ? (
            <Box marginBottom={ YodaSpacing.large } sx={ tacticGroupBoxStyle }>
              <SelectYoda { ...tacticFormConfig.tacticGroup } />
              {
                tacticGroupLoading ? (
                  <CircularProgress sx={ tacticGroupCircularProgressStyle } size={ tacticGroupCircularProgressSize }></CircularProgress>
                ) : null
              }
              <Box marginTop={ YodaFontSize.xxSmall } sx={ tacticGroupButtonBoxStyle }>
                <ActionButton sx={ actionButtonStyle } { ...tacticFormConfig.createTacticGroup }></ActionButton>
                {
                  tacticFormConfig.tacticGroupTooltip.listItems.length ? (
                    <TooltipList { ...tacticFormConfig.tacticGroupTooltip } >
                      <ActionButton sx={ actionButtonStyle } { ...tacticFormConfig.tacticGroupTooltipButton }></ActionButton>
                    </TooltipList>
                  ) : null
                }
              </Box>
            </Box>
          ) : null
      }
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.assetId } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...tacticFormConfig.assetName } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.callToAction } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.approvedDocumentId } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...tacticFormConfig.clickUrl } />
      </Box>
    </>
  );
};
