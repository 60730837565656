import { useFlags } from 'launchdarkly-react-client-sdk';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { therapeuticAreaRelativesProducts } from '../basics/constants/common.constants';
import { BiogenProduct } from '../basics/enums/biogen.enums';
import useGetAllProductsLazy from '../basics/graphql/queries/getAllProductsLazy';
import { ProductType } from '../basics/types/products.types';
import { CurrentUserStateContext } from './user';

export const ProductsContext = createContext<ProductType[]>([]);

export const ProductsProvider = ({ children }: { children: ReactNode }) => {
  const flags = useFlags();
  const [allProducts, setAllProducts] = useState<ProductType[]>([]);
  const userContextValue = useContext(CurrentUserStateContext);
  const { getAllProductsLazy } = useGetAllProductsLazy();

  const getAllProducts = useCallback(async () => {
    if (userContextValue.currentUserData.currentUser && !userContextValue.currentUserData.loading) {
      if (flags.mayaBdcfcsd2599EnableDynamicProductDisplay) {
        const { data } = await getAllProductsLazy();
        if (data?.getAllProducts) {
          setAllProducts(data.getAllProducts);
        }
      } else {
        const allStaticProducts = Object.entries(BiogenProduct).map(([key, value]) => ({
          productExternalID: value,
          productID: key,
          productName: key,
          therapeuticArea: Object.keys(therapeuticAreaRelativesProducts)
            .find((ta) => therapeuticAreaRelativesProducts[ta as keyof typeof therapeuticAreaRelativesProducts]
              ?.includes(value)) || '',
        }));

        setAllProducts(allStaticProducts);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    flags.mayaBdcfcsd2599EnableDynamicProductDisplay,
    userContextValue.currentUserData.loading,
    getAllProductsLazy,
  ]);

  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  return (
    <ProductsContext.Provider value={ allProducts }>
      { children }
    </ProductsContext.Provider>
  );
};
